import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import {
  selectHasInAppOffer,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import {
  selectIsDiscountApplied,
  selectIsInAppAvailable,
  selectIsUpsellAvailable,
  selectUserCountryCode,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { ExclusiveUpsellSubscriptions } from 'modules/purchase/components/ExclusiveUpsellSubscriptions'
import { SkipUpsellWithTimerModal } from 'modules/purchase/components/SkipUpsellWithTimerModal'
import { UpsellDisclaimer } from 'modules/purchase/components/UpsellDisclaimer'
import { TEN_MINUTES, UpsellProductVariant } from 'modules/purchase/constants'
import {
  usePricesStatus,
  usePurchaseStore,
  useTimerForTarget,
} from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'

import { eventLogger } from 'services/eventLogger.service'

import timerIcon from 'assets/images/clock.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Country,
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
  UpsellProduct,
} from 'root-constants'

import { StyledUpsellWithTimerExclusiveProducts as S } from './UpsellWithTimerExclusiveProducts.styles'
import { UPSELL_AI_BENEFITS, UPSELL_BASE_BENEFITS } from './constants'

export const UpsellWithTimerExclusiveProducts: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { fetchingActionsList, language } = usePurchaseStore()
  const { arePricesReady } = usePricesStatus()
  const { search } = useLocation()

  const testedUpsellProductsValue = useFeatureValue(
    'lv_576_upsell_cosmetic_scanner_test',
    'variant_b',
  )
  const userCountryCode = useSelector(selectUserCountryCode)
  const isInAppAvailable = useSelector(selectIsInAppAvailable)
  const hasInAppOffer = useSelector(selectHasInAppOffer)
  const isUpsellAvailable = useSelector(selectIsUpsellAvailable)
  const subscriptions = useSelector(selectSubscriptionList)
  const isDiscountApplied = useSelector(selectIsDiscountApplied)

  const timerElementRef = useRef<HTMLSpanElement | null>(null)

  const [isPricesStartedFetching, setIsPricesStartedFetching] =
    useState<boolean>(false)
  const [isSkipModalShown, setIsSkipModalShown] = useState<boolean>(false)

  useTimerForTarget(timerElementRef, TEN_MINUTES.IN_SECONDS)

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )

  const isCancelOfferRoute = useMemo(
    () => currentPageId === PageId.UPSELL_CANCEL_PAYWALL,
    [currentPageId],
  )

  const screenName = useMemo(
    () =>
      isCancelOfferRoute
        ? ScreenName.UPSELL_CANCEL_OFFER
        : ScreenName.UPSELL_3_PLANS,
    [isCancelOfferRoute],
  )

  const isAiProducts = useMemo(
    () =>
      testedUpsellProductsValue === UpsellProductVariant.AI_PRODUCTS ||
      testedUpsellProductsValue === UpsellProductVariant.HIGH_PRICES,
    [testedUpsellProductsValue],
  )

  const benefitsList = useMemo(
    () => (isAiProducts ? UPSELL_AI_BENEFITS : UPSELL_BASE_BENEFITS),
    [isAiProducts],
  )

  const isGuashaUpsellAvailable = useMemo(() => {
    return (
      !isDiscountApplied &&
      isInAppAvailable &&
      userCountryCode === Country.USA &&
      isEnglishLang &&
      hasInAppOffer
    )
  }, [
    isDiscountApplied,
    isInAppAvailable,
    isEnglishLang,
    userCountryCode,
    hasInAppOffer,
  ])

  const tags = useMemo(() => {
    const upsellTags: SubscriptionTagsType[] = [SubscriptionTagsType.NO_TAX]

    userCountryCode === Country.USA
      ? upsellTags.push(SubscriptionTagsType.USD)
      : upsellTags.push(SubscriptionTagsType.WORLDWIDE)

    testedUpsellProductsValue === UpsellProductVariant.AI_PRODUCTS &&
      upsellTags.push(SubscriptionTagsType.AI_PRODUCTS)

    testedUpsellProductsValue === UpsellProductVariant.HIGH_PRICES &&
      upsellTags.push(
        SubscriptionTagsType.AI_PRODUCTS,
        SubscriptionTagsType.HIGH_PRICES,
      )

    isCancelOfferRoute && upsellTags.push(SubscriptionTagsType.CANCEL_OFFER)

    return upsellTags.join(',')
  }, [isCancelOfferRoute, testedUpsellProductsValue, userCountryCode])

  useLayoutEffect(() => {
    if (isUpsellAvailable) {
      dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))
      setIsPricesStartedFetching(true)
      return
    }

    if (isInAppAvailable && hasInAppOffer) {
      goTo({ pathname: PageId.IN_APP_PAYWALL, search })
    }

    goTo({ pathname: PageId.FINISHING_TOUCHES, search })
  }, [
    search,
    isUpsellAvailable,
    dispatch,
    tags,
    isInAppAvailable,
    hasInAppOffer,
  ])

  useLayoutEffect(() => {
    const bothBaseProductsSubscription = subscriptions.find(
      (item) => item.product === UpsellProduct.MIX,
    )

    if (
      testedUpsellProductsValue === UpsellProductVariant.BASE_PRODUCTS &&
      bothBaseProductsSubscription
    ) {
      dispatch(setSelectedSubscriptionAction(bothBaseProductsSubscription))
      return
    }

    const bothBaseAiSubscription = subscriptions.find(
      (item) => item.product === UpsellProduct.AI_MIX,
    )

    if (isAiProducts && bothBaseAiSubscription) {
      dispatch(setSelectedSubscriptionAction(bothBaseAiSubscription))
    }
  }, [dispatch, isAiProducts, subscriptions, testedUpsellProductsValue])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logUpsellPurchaseShown(screenName)
    }
  }, [subscriptions, isPricesStartedFetching, arePricesReady, screenName])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      goTo({
        pathname:
          isInAppAvailable && hasInAppOffer
            ? PageId.IN_APP_PAYWALL
            : PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    arePricesReady,
    hasInAppOffer,
    isInAppAvailable,
    isPricesStartedFetching,
    search,
    subscriptions,
  ])

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const makeUpsell = useCallback(() => {
    dispatch(makeUpsellAction(screenName))
  }, [dispatch, screenName])

  const handleContinueWithoutPurchase = useCallback(() => {
    eventLogger.logUpsellPurchaseClose(screenName)

    goTo({
      pathname: isGuashaUpsellAvailable
        ? PageId.IN_APP_PAYWALL
        : PageId.FINISHING_TOUCHES,
      search,
    })
  }, [screenName, isGuashaUpsellAvailable, search])

  const openSkipModal = useCallback(() => {
    setIsSkipModalShown(true)
    eventLogger.logUpsellModalOpened()
  }, [])

  const closeModal = useCallback(() => {
    setIsSkipModalShown(false)
    setIsPricesStartedFetching(false)
    eventLogger.logUpsellModalClosed()
    goTo({
      pathname: PageId.UPSELL_CANCEL_PAYWALL,
      search,
    })
  }, [search])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <Container>
      <S.SkipButton
        onClick={
          isCancelOfferRoute ? handleContinueWithoutPurchase : openSkipModal
        }
      >
        {t('actions.skip')}
      </S.SkipButton>
      <S.Title>
        {isCancelOfferRoute
          ? t('upsellWithTimerExclusive.titleCancel')
          : t('upsellWithTimerExclusive.title')}
      </S.Title>
      <S.TimerContainer>
        <S.TimerIcon src={timerIcon} alt="timer" />
        <S.TimerContainerText>
          {t('upsellWithTimerExclusive.timerText')}{' '}
          <span ref={timerElementRef} /> {t('purchase7.min')}
        </S.TimerContainerText>
      </S.TimerContainer>
      <ExclusiveUpsellSubscriptions />
      <Button onClick={makeUpsell}>{t`upsellWithTimerExclusive.cta`}</Button>
      <S.Subtitle>{t('upsellWithTimerExclusive.benefits.subtitle')}</S.Subtitle>
      <S.List>
        {benefitsList.map(({ id, text }) => (
          <S.ListItem key={id}>
            <Trans i18nKey={text} components={[<strong />]} />
          </S.ListItem>
        ))}
      </S.List>
      <UpsellDisclaimer />
      {isSkipModalShown && (
        <SkipUpsellWithTimerModal handleCloseModal={closeModal} />
      )}
      {isUpsellInProgress && <Spinner />}
    </Container>
  )
}
