import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'

import { UpsellProductVariant } from 'modules/purchase/constants'

import gift from 'assets/images/upsell-gift.png'
import aiUpsellProgress from 'assets/images/upsell-skip-ai-chart.png'
import baseUpsellProgress from 'assets/images/upsell-skip-base-chart.png'

import { StyledSkipUpsellWithTimerModal as S } from './SkipUpsellWithTimerModal.styles'

type TSkipUpsellWithTimerModalProps = {
  handleCloseModal: () => void
}

export const SkipUpsellWithTimerModal: React.FC<
  TSkipUpsellWithTimerModalProps
> = ({ handleCloseModal }: TSkipUpsellWithTimerModalProps) => {
  const { t } = useTranslation()
  const testedUpsellProductsValue = useFeatureValue(
    'lv_576_upsell_cosmetic_scanner_test',
    'b',
  )

  const content = useMemo(
    () =>
      testedUpsellProductsValue === UpsellProductVariant.AI_PRODUCTS ||
      testedUpsellProductsValue === UpsellProductVariant.HIGH_PRICES
        ? {
            description:
              'upsellWithTimerExclusive.skipModal.descriptionAIProducts',
            image: aiUpsellProgress,
          }
        : {
            description:
              'upsellWithTimerExclusive.skipModal.descriptionBaseProducts',
            image: baseUpsellProgress,
          },
    [testedUpsellProductsValue],
  )

  return (
    <S.Root>
      <S.Content>
        <S.Title>{t('upsellWithTimerExclusive.skipModal.title')}</S.Title>
        <S.Subtitle>{t(content.description)}</S.Subtitle>
        <S.Chart src={content.image} alt="upsell-chart" />
        <S.GiftContainer>
          <S.GiftImage src={gift} alt="gift" />
          <S.GiftNote>
            <Trans
              i18nKey="upsellWithTimerExclusive.skipModal.note"
              components={[<strong />]}
            />
          </S.GiftNote>
        </S.GiftContainer>
        <Button onClick={handleCloseModal}>{t('actions.continue')}</Button>
      </S.Content>
    </S.Root>
  )
}
