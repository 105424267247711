import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSkipUpsellWithTimerModal = {
  Root: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  `,
  Content: styled.div`
    max-width: 328px;
    padding: 24px 20px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Title: styled.p`
    color: ${Color.DARK};
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    /* margin-bottom: 16px; */
  `,
  Subtitle: styled.p`
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  `,
  Chart: styled.img``,
  GiftContainer: styled.div`
    border: 1px solid ${Color.SILVER_GRAY};
    border-radius: 16px;
    display: flex;
    height: 100px;
  `,
  GiftImage: styled.img`
    width: 80px;
    border-radius: 16px 0 0 16px;
  `,
  GiftNote: styled.p`
    padding: 20px 12px;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.LIGHT};

    & > strong {
      color: ${Color.PRIMARY};
      font-weight: 400;
    }
  `,
}
