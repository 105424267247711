import styled, { css } from 'styled-components'

import productSelectedIcon from 'assets/images/primary-check.png'

import { Color } from 'root-constants'

type TCurrencyProps = {
  isMxnCurrency: boolean
}

type TProductOptionProps = {
  isSelected: boolean
}

export const StyledExclusiveUpsellSubscriptions = {
  OptionsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 24px;
  `,
  ProductItem: styled.div<TProductOptionProps>`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 16px;
    border: ${({ isSelected }) =>
      isSelected
        ? `2px solid ${Color.PRIMARY}`
        : `1px solid ${Color.SILVER_GRAY}`};
    margin-bottom: 12px;
  `,
  ProductImage: styled.img`
    width: 80px;
    border-radius: 14px 0 0 14px;
  `,
  Discount: styled.div<TProductOptionProps>`
    position: absolute;
    top: -12px;
    right: -2px;
    padding: 2px 12px;
    height: 24px;
    border-radius: 16px 16px 0 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    z-index: 2;

    ${({ isSelected }) =>
      isSelected
        ? css`
            background-color: ${Color.PRIMARY};
            color: ${Color.WHITE};
          `
        : css`
            background-color: #ffe1de;
            color: ${Color.PRIMARY};
          `}
  `,
  ProductDescription: styled.div<TProductOptionProps>`
    position: relative;
    padding: 16px 32px 16px 12px;
    flex: 1;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 1px solid #aaacb2;
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
            background-image: url(${productSelectedIcon});
            background-size: 20px 20px;
            background-repeat: no-repeat;
            opacity: 1;
            border: none;
          `}
    }
  `,
  ProductName: styled.p`
    color: ${Color.DARK};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  `,
  ProductPrices: styled.div<TCurrencyProps>`
    display: flex;
    align-items: center;
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '11px' : '14px')};
    line-height: 20px;
  `,
  ProductOldPrice: styled.p`
    color: ${Color.LIGHT};
    position: relative;
    text-decoration: line-through;
    margin-left: 5px;
  `,
  ProductCurrentPrice: styled.p`
    color: ${Color.RED};
  `,
}
