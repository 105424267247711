import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import minusIcon from 'assets/images/minus-accordion-icon.svg'
import plusIcon from 'assets/images/plus-accordion-icon.svg'

import { Color } from 'root-constants'

export const StyledCookieConsent = {
  Root: styled.div<{ fullHeight: boolean }>`
    width: 100%;
    box-shadow: 0px 8px 20px 0px #cccfde;
    background-color: ${Color.WHITE};
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    font-family: Rubik;
    ${({ fullHeight }) => {
      return (
        fullHeight &&
        css`
          height: 100vh;
          height: 100dvh;
          overflow-y: auto;
        `
      )
    }};
  `,
  Header: styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e4e4e7;
    padding: 13px 16px 13px 24px;
  `,
  CloseButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
  `,
  Container: styled.div`
    max-width: 336px;
    margin: 0 auto;
    padding: 16px 0;
  `,
  Title: styled.h2`
    color: ${Color.BLUE_DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  SecondaryTitle: styled.h3`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.MIDNIGHT_BLUE};
    margin-bottom: 12px;
  `,
  Description: styled.p`
    color: ${Color.LIGHT};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
    & a {
      text-decoration: underline;
      color: ${Color.LIGHT};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  PrimaryButton: styled(Button)`
    font-size: 14px;
    border-radius: 6px;
    height: 40px;
    margin-bottom: 16px;
  `,
  SecondaryButton: styled(Button)`
    border-radius: 6px;
    height: 40px;
    background-color: ${Color.CORAL_GRAY};
    margin-bottom: 16px;
    color: ${Color.PRIMARY};
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Settings: styled.ul`
    border: 1px solid #e5e7eb;
    border-bottom: none;
    margin-bottom: 24px;
  `,
  SettingItem: styled.li`
    position: relative;

    & label {
      position: absolute;
      right: 16px;
      top: 20px;
    }
  `,
  SettingsTitle: styled.h2`
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${Color.MIDNIGHT_BLUE};
    margin: 32px auto 20px;
  `,
  Setting: styled.details`
    &[open] summary::after {
      background-image: url(${minusIcon});
    }

    &:last-child span {
      flex-basis: 180px;
    }
  `,
  SettingName: styled.summary`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 41px;
    font-size: 14px;
    font-style: normal;
    min-height: 64px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: 1px solid #e5e7eb;
    position: relative;

    &::-webkit-details-marker {
      display: none;
    }

    & span {
      color: ${Color.BLUE_DARK};
      flex-basis: 200px;
    }

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url(${plusIcon});
      background-size: contain;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  SettingDetails: styled.p`
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 16px;
    color: #4b5563;
    border-bottom: 1px solid #e5e7eb;
  `,
  ActiveLabel: styled.strong`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.PRIMARY};
  `,
}
