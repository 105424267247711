import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import {
  selectIsDiscountApplied,
  selectIsInAppAvailable,
  selectUserCountryCode,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { getButtonTapEventData } from 'helpers/getGuashaButtonsEventData'
import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { SkipUpsellGuashaModal } from 'modules/purchase/components/SkipUpsellGuashaModal'
import { UpsellGuashaCancelOffer } from 'modules/purchase/components/UpsellGuashaCancelOffer'
import { UpsellGuashaDescription } from 'modules/purchase/components/UpsellGuashaDescription'
import { UpsellGuashaFacts } from 'modules/purchase/components/UpsellGuashaFacts'
import { UpsellGuashaPersonalBonus } from 'modules/purchase/components/UpsellGuashaPersonalBonus'
import { UpsellGuashaReviews } from 'modules/purchase/components/UpsellGuashaReviews'
import { UpsellGuashaSubscriptionBlock } from 'modules/purchase/components/UpsellGuashaSubscriptionBlock'
import { UpsellGuashaUsers } from 'modules/purchase/components/UpsellGuashaUsers'
import { usePricesStatus, usePurchaseStore } from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import beforeAfter from 'assets/images/before-after-guasha.jpg'
import guashaSkinCare from 'assets/images/guasha-skin-care.png'
import dizzyIcon from 'assets/images/sprite/dizzy.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Country,
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
  UpsellProductType,
} from 'root-constants'

import { CheckoutGuasha } from '../CheckoutGuasha'
import { StyledUpsellGuasha as S } from './UpsellGuasha.styles'
import { TRACKED_BREAKPOINTS } from './constants'

export const UpsellGuasha: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()
  const isTestOn = useFeatureIsOn('lv_77_guasha_prices_test')
  const testedPriceValue = useFeatureValue(
    'lv_77_guasha_prices_test',
    'price_a',
  )

  const isInAppAvailable = useSelector(selectIsInAppAvailable)
  const selectedSubscription = useSelector(selectSubscription)
  const isDiscountApplied = useSelector(selectIsDiscountApplied)
  const userCountryCode = useSelector(selectUserCountryCode)

  const { language } = usePurchaseStore()
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)
  const [isSkipModalShown, setIsSkipModalShown] = useState(false)
  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)

  const { arePricesReady } = usePricesStatus()
  const { subscriptions } = usePurchaseStore()

  const isCancelOffer = useMemo(() => {
    const currentPageId = getPageIdFromPathName(pathname)
    return (
      currentPageId === PageId.IN_APP_CANCEL_PAYWALL ||
      currentPageId === PageId.LIVECHAT_UPSELL_CANCEL_IN_APP
    )
  }, [pathname])

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const screenName = useMemo(
    () =>
      isCancelOffer
        ? ScreenName.UPSELL_GUASHA_CANCEL
        : ScreenName.UPSELL_GUASHA,
    [isCancelOffer],
  )

  const scrollEvent = (scrolledPart: number) => {
    eventLogger.logInAppPageScrolled({
      scrolledPart,
      screenName,
    })
  }

  useTrackPageScrollDepth({
    trackedBreakpoints: TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
    preventSendingEvents: isCheckoutShown,
  })

  useLayoutEffect(() => {
    if (isCancelOffer) {
      goTo({ pathname: PageId.IN_APP_CANCEL_PAYWALL, search })
    }
  }, [isCancelOffer, search])

  useLayoutEffect(() => {
    const isGuashaUpsellUnavailable =
      isDiscountApplied ||
      !isInAppAvailable ||
      userCountryCode !== Country.USA ||
      !isEnglishLang
    if (isGuashaUpsellUnavailable) {
      goTo({ pathname: PageId.FINISHING_TOUCHES, search })
    }
    if (!isTestOn) return

    const guashaTags = isCancelOffer
      ? SubscriptionTagsType.GUASHA_CANCEL
      : SubscriptionTagsType.GUASHA

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.ONE_TIME,
        `${SubscriptionTagsType.NO_TAX},${guashaTags}`,
      ),
    )
    setIsPricesStartedFetching(true)
  }, [
    search,
    dispatch,
    isInAppAvailable,
    isDiscountApplied,
    isTestOn,
    testedPriceValue,
    userCountryCode,
    isEnglishLang,
    isCancelOffer,
  ])

  useEffect(() => {
    const guaShaSubscription =
      subscriptions.find(
        (subscription) => subscription?.type === UpsellProductType.ONE_TIME,
      ) || subscriptions[0]

    dispatch(setSelectedSubscriptionAction(guaShaSubscription))
  }, [dispatch, subscriptions])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      goTo({
        pathname: PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    isPricesStartedFetching,
    arePricesReady,
    isInAppAvailable,
  ])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logUpsellPurchaseShown(screenName)
    }
  }, [
    arePricesReady,
    isPricesStartedFetching,
    screenName,
    subscriptions.length,
  ])

  const openSkipModal = useCallback(
    (event) => {
      const { buttonText, buttonOrder } = getButtonTapEventData(event)
      eventLogger.logInAppButtonTap(buttonText, buttonOrder, screenName)
      setIsSkipModalShown(true)
    },
    [screenName],
  )

  const skipInApp = useCallback(
    (event) => {
      const { buttonText, buttonOrder } = getButtonTapEventData(event)
      eventLogger.logInAppButtonTap(buttonText, buttonOrder, screenName)
      goTo({ pathname: PageId.FINISHING_TOUCHES, search })
    },
    [screenName, search],
  )

  const closeSkipModal = useCallback(() => {
    setIsSkipModalShown(false)
    setIsPricesStartedFetching(false)
    goTo({ pathname: PageId.IN_APP_CANCEL_PAYWALL, search })
  }, [search])

  const openCancelOfferModalFromCheckout = useCallback(() => {
    setIsCheckoutShown(false)
    setIsSkipModalShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Root>
      {!isCheckoutShown && (
        <S.Wrapper id="guasha-wrapper">
          <S.SkipButton
            onClick={isCancelOffer ? skipInApp : openSkipModal}
            data-order={1}
          >
            {t('actions.skip')}
          </S.SkipButton>
          {isCancelOffer && <UpsellGuashaCancelOffer />}
          <S.Hero>
            <img src={beforeAfter} alt="" />
          </S.Hero>
          <UpsellGuashaDescription />
          {selectedSubscription && (
            <UpsellGuashaSubscriptionBlock
              onAddToCartCallback={() => setIsCheckoutShown(true)}
              buttonsOrder={[2]}
              isCancelOffer={isCancelOffer}
            />
          )}
          <UpsellGuashaUsers />
          <UpsellGuashaFacts
            onAddToCartCallback={() => setIsCheckoutShown(true)}
            buttonOrder={3}
            screenName={screenName}
          />
          <UpsellGuashaPersonalBonus
            title="guashaUpsell.skinCareTitle"
            description="guashaUpsell.skinCareDescription"
            img={guashaSkinCare}
            icon={dizzyIcon}
            backgroundIconColor="#83BAB3"
          />
          <Container fields={16}>
            <S.CTAImage src={beforeAfter} alt="" />
            <S.CTA>
              <Trans
                i18nKey="guashaUpsell.cta"
                components={[<br />, <strong />]}
              />
            </S.CTA>
          </Container>
          {selectedSubscription && (
            <UpsellGuashaSubscriptionBlock
              onAddToCartCallback={() => setIsCheckoutShown(true)}
              buttonsOrder={[4]}
              isCancelOffer={isCancelOffer}
            />
          )}
          <UpsellGuashaReviews />
          {isSkipModalShown && (
            <SkipUpsellGuashaModal closeModal={closeSkipModal} />
          )}
        </S.Wrapper>
      )}
      {isCheckoutShown && (
        <CheckoutGuasha
          onClose={openCancelOfferModalFromCheckout}
          isCancelOffer={isCancelOffer}
        />
      )}
    </S.Root>
  )
}
