import React from 'react'
import { Trans } from 'react-i18next'

import refundImage from 'assets/images/refund-usd-icon.png'
import topRefundImage from 'assets/images/refund-wallet-icon.png'

import { StyledPurchaseRefundBanner as S } from './PurchaseRefundBanner.styles'

export type TPurchaseRefundBannerProps = {
  isTopPosition?: boolean
}

export const PurchaseRefundBanner: React.FC<TPurchaseRefundBannerProps> = ({
  isTopPosition = false,
}: TPurchaseRefundBannerProps) => {
  return (
    <S.RefundBannerContainer isTopBanner={isTopPosition}>
      <S.RefundBanner isTopBanner={isTopPosition}>
        <S.RefundBannerImage
          src={isTopPosition ? topRefundImage : refundImage}
          isTopBanner={isTopPosition}
        />
        <div>
          <Trans i18nKey="purchaseRefund.bannerText" components={[<br />]} />
        </div>
      </S.RefundBanner>
    </S.RefundBannerContainer>
  )
}
