import styled, { css } from 'styled-components'

import checkIconBlue from 'assets/images/check-icon-light-blue.svg'
import chevronRight from 'assets/images/chevron-right-coral.png'

import { Color } from 'root-constants'

const subtitleStyles = css`
  text-align: center;
  color: ${Color.DARK};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
`

export const StyledUpsellWithTimerExclusiveProducts = {
  Title: styled.h1`
    ${subtitleStyles}
  `,
  SkipButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    color: ${Color.PRIMARY};
    position: absolute;
    top: 13px;
    right: 10px;
    z-index: 5;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${chevronRight}) no-repeat center;
      background-size: contain;
    }
  `,
  TimerContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 12px;
    padding: 12px 24px;
    background-color: #89a8fd;
    margin-bottom: 26px;
  `,
  Subtitle: styled.h3`
    ${subtitleStyles}
    margin-top: 40px;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  `,
  ListItem: styled.li`
    position: relative;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    padding: 0 5px 0 34px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(${checkIconBlue}) center no-repeat;
      background-size: contain;
    }
  `,
  TimerContainerText: styled.span`
    color: ${Color.WHITE};
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  `,
  TimerIcon: styled.img`
    width: 20px;
    height: 20px;
    margin-right: 6px;
  `,
}
