import React from 'react'
import { Trans } from 'react-i18next'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { SUBSCRIPTION_DISCOUNTS } from 'modules/purchase/pages/PurchaseVariant7/constants'

import { StyledCancelOfferDiscountInfo as S } from './CancelOfferDiscountInfo.styles'

export const CancelOfferDiscountInfo = () => {
  const { isIntro3SaleCohort } = useActiveCohortIdentifier()

  return (
    <S.Root data-testid="cancel-offer-container">
      <Trans
        i18nKey="purchase7.cancelOfferDiscount"
        components={[<i />, <strong />]}
        values={{
          discount: isIntro3SaleCohort
            ? SUBSCRIPTION_DISCOUNTS.CANCEL_INTRO3_SALE
            : SUBSCRIPTION_DISCOUNTS.CANCEL_DEFAULT,
          prevDiscount: isIntro3SaleCohort
            ? SUBSCRIPTION_DISCOUNTS.INTRO3_SALE
            : SUBSCRIPTION_DISCOUNTS.DEFAULT,
        }}
      />
    </S.Root>
  )
}
