export const UPSELL_BASE_BENEFITS = [
  { id: 1, text: 'upsellWithTimerExclusive.benefits.base.option1' },
  { id: 2, text: 'upsellWithTimerExclusive.benefits.base.option2' },
  { id: 3, text: 'upsellWithTimerExclusive.benefits.base.option3' },
  { id: 4, text: 'upsellWithTimerExclusive.benefits.base.option4' },
]

export const UPSELL_AI_BENEFITS = [
  { id: 1, text: 'upsellWithTimerExclusive.benefits.ai.option1' },
  { id: 2, text: 'upsellWithTimerExclusive.benefits.ai.option2' },
  { id: 3, text: 'upsellWithTimerExclusive.benefits.ai.option3' },
  { id: 4, text: 'upsellWithTimerExclusive.benefits.ai.option4' },
]
