import { IUserStatus, IUserStatusRaw } from 'models/user.model'

import { LOGIN_METHOD_RAW_MAP } from 'root-constants'

export const getUserStatusFromRawUserStatus = (
  userStatusRaw: IUserStatusRaw,
): IUserStatus => ({
  countryCode: userStatusRaw.country_code,
  isOnboardingSkipped: userStatusRaw.onboarding.is_skipped,
  onboarding: userStatusRaw.onboarding.quiz,
  config: {
    paymentCurrency: userStatusRaw.config?.payment_currency || '',
    paymentMethod: userStatusRaw.config?.payment_method || '',
    isDownloadVisited: userStatusRaw.config?.is_download_visited || false,
    isDiscountApplied: userStatusRaw.config?.discount_applied || false,
    subscriptionPrice: userStatusRaw.config?.subscription_price || '',
    subscriptionDuration: userStatusRaw.config?.subscription_duration || '',
    priceId: userStatusRaw.config?.price_id || '',
    trialPeriod: userStatusRaw.config?.trial_period || '',
    trialPrice: userStatusRaw.config?.trial_price || '',
    screenName: userStatusRaw.config?.screen_name,
    periodQuantity: userStatusRaw.config?.period_quantity,
    periodName: userStatusRaw.config?.period_name,
    paymentSystem: userStatusRaw.config?.payment_system,
    upsellProductName: userStatusRaw.config?.upsell_product,
    cookieConsent: userStatusRaw.config?.cookie_consent,
    failedInAppProductName: userStatusRaw.config?.failed_inapp_product,
    utmTags: userStatusRaw.config?.utm_tags,
    itemName: userStatusRaw.config?.item_name,
    itemPrice: userStatusRaw.config?.item_price,
    dynamicUpsellPages: userStatusRaw.config?.dynamic_upsell_pages,
  },
  email: {
    hasEmail: userStatusRaw.email.has_email,
    hasEmailConsent: userStatusRaw.email.has_email_consent,
    isEmailConsentTried: userStatusRaw.email.is_email_consent_tried,
    onboardingEmail: userStatusRaw.email.onboarding_email,
  },
  hasSubscription: userStatusRaw.subscription.has_subscription,
  account: {
    hasAccount: userStatusRaw.account.has_account,
    loginMethod:
      LOGIN_METHOD_RAW_MAP[userStatusRaw.account?.login_method] || null,
    email: userStatusRaw.email.onboarding_email,
  },
  upsell: {
    isUpsellAvailable: userStatusRaw.upsell.is_upsell_available,
  },
  inapp: {
    isInAppAvailable: userStatusRaw.inapp.is_inapp_available,
  },
  upgrade: {
    isUpgradePassed: userStatusRaw.upgrade.is_upgrade_passed,
    isUpgradeAvailable: userStatusRaw.upgrade.is_upgrade_available,
  },
})
