import { Cohort } from 'root-constants'

export const LONG_PAYWALL_COHORTS: Cohort[] = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_CANCEL_INTRO_FAST,
  Cohort.LUVLY_EMAIL_INTRO_FAST,
  Cohort.LUVLY_9,
  Cohort.LUVLY_10,
  Cohort.LUVLY_12,
  Cohort.LUVLY_INTRO2T,
  Cohort.LUVLY_14,
  Cohort.LUVLY_15,
  Cohort.LUVLY_17,
  Cohort.LUVLY_18,
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_EMAIL_8,
  Cohort.LUVLY_19,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_20,
]
