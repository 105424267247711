import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getUserStatusAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUUID, selectUserConfigData } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { UPSELL_PAGES_MAP } from 'page-constants'
import { Cohort } from 'root-constants'

export const useSetUserStatus = () => {
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const config = useSelector(selectUserConfigData)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort || Cohort.QUIZ_2

  useEffect(() => {
    if (uuid) {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])

  useEffect(() => {
    if (!config) return
    if (!config?.dynamicUpsellPages) {
      dispatch(
        sendUserConfigAction({
          dynamic_upsell_pages: UPSELL_PAGES_MAP[cohortToUse],
        }),
      )
    }
  }, [dispatch, config, cohortToUse])
}
