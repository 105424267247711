import React, { useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { Option } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectUserPaymentCurrency } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'
import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import {
  CURRENCY_SYMBOLS,
  Currency,
  SubscriptionPeriod,
  UpsellProductVariant,
} from 'modules/purchase/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionPeriodQuantity,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'
import {
  OptionType,
  UPSELL_PRODUCT_EVENT_NAMES_MAP,
  UpsellProduct,
} from 'root-constants'

import { StyledExclusiveUpsellSubscriptions as S } from './ExclusiveUpsellSubscriptions.styles'
import {
  DEFAULT_UPSELL_DISCOUNT,
  UPSELL_AI_SUBSCRIPTIONS,
  UPSELL_BASE_SUBSCRIPTIONS,
} from './constants'

export const ExclusiveUpsellSubscriptions: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const { pathname } = useLocation()
  const testedUpsellProductsValue = useFeatureValue(
    'lv_576_upsell_cosmetic_scanner_test',
    'variant_b',
  )
  const subscription = useSelector(selectSubscription)
  const currency = useSelector(selectUserPaymentCurrency)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const subscriptions = useSelector(selectSubscriptionList)

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )

  const isCancelOfferRoute = useMemo(
    () => currentPageId === PageId.UPSELL_CANCEL_PAYWALL,
    [currentPageId],
  )

  const isMxnCurrency = useMemo(() => currency === Currency.MXN, [currency])

  const getPriceLabel = useCallback(
    (amount: number) => `${CURRENCY_SYMBOLS[currency]}${amount}`,
    [currency],
  )

  const products = useMemo(
    () =>
      testedUpsellProductsValue === UpsellProductVariant.AI_PRODUCTS ||
      testedUpsellProductsValue === UpsellProductVariant.HIGH_PRICES
        ? UPSELL_AI_SUBSCRIPTIONS
        : UPSELL_BASE_SUBSCRIPTIONS,
    [testedUpsellProductsValue],
  )

  const handleChange = useCallback(
    (value: ISubscription, prevValue: ISubscription | null) => {
      dispatch(setSelectedSubscriptionAction(value))
      prevValue &&
        eventLogger.logUpsellSubscriptionDisable(
          UPSELL_PRODUCT_EVENT_NAMES_MAP[prevValue.product as UpsellProduct],
        )
      eventLogger.logUpsellSubscriptionEnable(
        UPSELL_PRODUCT_EVENT_NAMES_MAP[value.product as UpsellProduct],
      )
    },
    [dispatch],
  )

  return (
    <S.OptionsContainer>
      {products.map(({ text, image, value, extraCancelDiscount }) => {
        const upsellOption = subscriptions.find(
          (item) => item.product === value,
        )
        const discount =
          extraCancelDiscount && isCancelOfferRoute
            ? extraCancelDiscount
            : DEFAULT_UPSELL_DISCOUNT

        return (
          upsellOption && (
            <Option
              key={value}
              type={OptionType.RADIO}
              name="subscription"
              value={upsellOption.id}
              onChange={() => handleChange(upsellOption, subscription)}
              withoutMargin
            >
              <S.ProductItem isSelected={upsellOption.id === subscription?.id}>
                <S.Discount isSelected={upsellOption.id === subscription?.id}>
                  <Trans
                    i18nKey="upsellWithTimerExclusive.subscriptions.off"
                    values={{
                      value: discount,
                    }}
                  />
                </S.Discount>
                <S.ProductImage src={image} alt="subscription-image" />
                <S.ProductDescription
                  isSelected={upsellOption.id === subscription?.id}
                >
                  <S.ProductName>
                    <Trans i18nKey={text} components={[<br />]} />
                  </S.ProductName>
                  <S.ProductPrices isMxnCurrency={isMxnCurrency}>
                    <S.ProductCurrentPrice>
                      <Trans
                        i18nKey={
                          periodQuantity === SubscriptionPeriod.MONTHLY
                            ? 'upsellWithTimerExclusive.subscriptions.currentPriceOneMonth'
                            : 'upsellWithTimerExclusive.subscriptions.currentPriceSeveralMonths'
                        }
                        values={{
                          price: getPriceLabel(
                            upsellOption.mainPrices.fullPrice,
                          ),
                          period: periodQuantity,
                        }}
                      />
                    </S.ProductCurrentPrice>
                    <S.ProductOldPrice>
                      {getCalculatedPriceWithoutVAT(
                        upsellOption.mainPrices.oldPrices.fullPrice,
                        0,
                      )}
                    </S.ProductOldPrice>
                  </S.ProductPrices>
                </S.ProductDescription>
              </S.ProductItem>
            </Option>
          )
        )
      })}
    </S.OptionsContainer>
  )
}
