import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/Switch'

import {
  getUserStatusAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { eventLogger } from 'services/eventLogger.service'

import crossIcon from 'assets/images/sprite/close-icon-dark.svg'
import logoSvg from 'assets/images/sprite/logo.svg'

import { CookieConsentOption } from 'root-constants'

import { SvgImage } from '../SvgImage'
import { StyledCookieConsent as S } from './CookieConsent.styles'
import { ALL_COOKIE_CONSENT_TYPES, Source } from './constants'

export const CookieConsent = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(true)
  const [isSettingsOn, setIsSettingsOn] = useState(false)
  const [cookieConsentTypes, setCookieConsentTypes] = useState<string[]>([])

  useEffect(() => {
    if (!isCookieConsentVisible) {
      return
    }

    eventLogger.logCookiesConsentShown()
  }, [isCookieConsentVisible])

  const toggleSettingsVisibility = useCallback(() => {
    isSettingsOn
      ? eventLogger.logCookiesConsentSettingsScreenClose()
      : eventLogger.logCookiesConsentSettingsTap()
    setIsSettingsOn(!isSettingsOn)
  }, [isSettingsOn])

  const handleSubmit = useCallback(
    async (userAnswer: string[]) => {
      setIsCookieConsentVisible(false)
      await dispatch(
        sendUserConfigAction({
          cookie_consent: userAnswer,
        }),
      )
      dispatch(getUserStatusAction(uuid))
    },
    [dispatch, uuid],
  )

  const handleChange = useCallback(
    (event) => {
      if (cookieConsentTypes.includes(event.target.value)) {
        eventLogger.logCookiesConsentToggleDisable(event.target.value)
        const mutatedCookieConsentTypes = cookieConsentTypes.filter(
          (item) => item !== event.target.value,
        )
        setCookieConsentTypes([...mutatedCookieConsentTypes])

        return
      }

      eventLogger.logCookiesConsentToggleEnable(event.target.value)
      setCookieConsentTypes([...cookieConsentTypes, event.target.value])
    },
    [cookieConsentTypes],
  )

  if (!isCookieConsentVisible) {
    return null
  }

  return (
    <S.Root fullHeight={isSettingsOn}>
      {isSettingsOn && (
        <S.Header>
          <SvgImage svg={logoSvg} width={58} />
          <S.CloseButton onClick={toggleSettingsVisibility}>
            <SvgImage svg={crossIcon} width={24} />
          </S.CloseButton>
        </S.Header>
      )}
      <S.Container>
        {isSettingsOn && (
          <>
            <S.SecondaryTitle>
              {t('cookieConsent.privacyCenterTitle')}
            </S.SecondaryTitle>
            <S.Description>
              {t('cookieConsent.privacyCenterDescription')}
            </S.Description>
            <S.PrimaryButton
              onClick={() => {
                eventLogger.logCookiesConsentAcceptAllTap(Source.SETTINGS)
                handleSubmit(ALL_COOKIE_CONSENT_TYPES)
              }}
            >
              {t('cookieConsent.allowAll')}
            </S.PrimaryButton>
            <S.SettingsTitle>
              {t('cookieConsent.consentManagement')}
            </S.SettingsTitle>
            <S.Settings>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentOption.ADVERTIZING_AND_TARGETING,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.advertisingCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.advertisingCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <Switch
                  small
                  value={CookieConsentOption.ADVERTIZING_AND_TARGETING}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentOption.ADVERTIZING_AND_TARGETING,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentOption.FUNCTIONAL_COOKIES,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.functionalCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.functionalCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <Switch
                  small
                  value={CookieConsentOption.FUNCTIONAL_COOKIES}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentOption.FUNCTIONAL_COOKIES,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.analyticalCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.analyticalCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <Switch
                  small
                  value={CookieConsentOption.ANALYTICS_AND_PERFORMANCE}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting>
                  <S.SettingName>
                    <span>{t('cookieConsent.necessaryCookies')}</span>
                    <S.ActiveLabel>
                      {t('cookieConsent.activeLabel')}
                    </S.ActiveLabel>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.necessaryCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
              </S.SettingItem>
            </S.Settings>
          </>
        )}
        {!isSettingsOn && (
          <>
            <S.Title>{t('cookieConsent.title')}</S.Title>
            <S.Description>{t('cookieConsent.description')}</S.Description>
            <S.PrimaryButton
              onClick={() => {
                eventLogger.logCookiesConsentAcceptAllTap(Source.BANNER)
                handleSubmit(ALL_COOKIE_CONSENT_TYPES)
              }}
            >
              {t('cookieConsent.acceptAll')}
            </S.PrimaryButton>
            <S.SecondaryButton onClick={toggleSettingsVisibility}>
              {t('cookieConsent.settings')}
            </S.SecondaryButton>
          </>
        )}
        {isSettingsOn && (
          <>
            <S.PrimaryButton
              onClick={() => {
                eventLogger.logCookiesConsentAcceptAllTap(Source.SETTINGS)
                handleSubmit(ALL_COOKIE_CONSENT_TYPES)
              }}
            >
              {t('cookieConsent.allowAll')}
            </S.PrimaryButton>

            <S.SecondaryButton
              onClick={() => {
                eventLogger.logCookiesConsentSettingsConfirmChoice(
                  `${
                    cookieConsentTypes.toString() ||
                    CookieConsentOption.REJECT_ALL
                  }`,
                )
                handleSubmit(cookieConsentTypes)
              }}
            >
              {t('cookieConsent.confirmChoice')}
            </S.SecondaryButton>
          </>
        )}
        <S.SecondaryButton
          onClick={() => {
            handleSubmit([])
            eventLogger.logCookiesConsentRejectAllTap(
              isSettingsOn ? Source.SETTINGS : Source.BANNER,
            )
          }}
        >
          {t('cookieConsent.rejectAll')}
        </S.SecondaryButton>
      </S.Container>
    </S.Root>
  )
}
