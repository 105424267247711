import productScanner from 'assets/images/upsell-ai-product-scanner.png'
import skincareAssistant from 'assets/images/upsell-ai-skincare-assistant.png'
import doubleChin from 'assets/images/upsell-base-double-chin.png'
import faceLifting from 'assets/images/upsell-base-face-lifting.png'
import gift from 'assets/images/upsell-gift.png'

import { UpsellProduct } from 'root-constants'

export const DEFAULT_UPSELL_DISCOUNT = 30
const EXTRA_CANCEL_DISCOUNT = 50

export const UPSELL_BASE_SUBSCRIPTIONS = [
  {
    text: 'upsellWithTimerExclusive.subscriptions.base.option1',
    image: gift,
    value: UpsellProduct.MIX,
    extraCancelDiscount: EXTRA_CANCEL_DISCOUNT,
  },
  {
    text: 'upsellWithTimerExclusive.subscriptions.base.option2',
    image: doubleChin,
    value: UpsellProduct.CHIN_SHAPER,
  },
  {
    text: 'upsellWithTimerExclusive.subscriptions.base.option3',
    image: faceLifting,
    value: UpsellProduct.FACE_LIFTING,
  },
]

export const UPSELL_AI_SUBSCRIPTIONS = [
  {
    text: 'upsellWithTimerExclusive.subscriptions.ai.option1',
    image: gift,
    value: UpsellProduct.AI_MIX,
    extraCancelDiscount: EXTRA_CANCEL_DISCOUNT,
  },
  {
    text: 'upsellWithTimerExclusive.subscriptions.ai.option2',
    image: productScanner,
    value: UpsellProduct.PRODUCT_SCANNER,
  },
  {
    text: 'upsellWithTimerExclusive.subscriptions.ai.option3',
    image: skincareAssistant,
    value: UpsellProduct.SKINCARE_ASSISTANT,
  },
]
