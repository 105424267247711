import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { getPaymentConfigAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { UpsellProductVariant } from 'modules/purchase/constants'

import { UpsellPaywallSwitchers } from '../UpsellPaywallSwitchers'
import { UpsellWithTimerExclusiveProducts } from '../UpsellWithTimerExclusiveProducts'

export const UpsellProvider: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const { isLuvlyIntroFastCohort } = useActiveCohortIdentifier()
  const testedUpsellProductsValue = useFeatureValue(
    'lv_576_upsell_cosmetic_scanner_test',
    'variant_a',
  )

  useEffect(() => {
    dispatch(getPaymentConfigAction())
  }, [dispatch])

  const upsellWithTimer = useMemo(
    () =>
      testedUpsellProductsValue !== UpsellProductVariant.DEFAULT &&
      isLuvlyIntroFastCohort,
    [isLuvlyIntroFastCohort, testedUpsellProductsValue],
  )

  return upsellWithTimer ? (
    <UpsellWithTimerExclusiveProducts />
  ) : (
    <UpsellPaywallSwitchers />
  )
}
