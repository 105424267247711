import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellDisclaimer = {
  Wrapper: styled.div`
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    color: ${Color.LIGHT};
    padding-bottom: 24px;

    & a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
}
