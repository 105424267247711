/* eslint-disable max-lines */
import { Login } from 'modules/login'
import {
  CancelOfferQuestion,
  HolidaysPurchaseCancelOffer,
  PurchaseCancelOffer,
  PurchaseCosmeticScanner,
  PurchaseRefund,
  PurchaseUpgrade,
  PurchaseVariant2,
  PurchaseVariant4,
  PurchaseVariant7,
  UpsellGuasha,
  UpsellPaywallSwitchers,
  UpsellWellnessBundle,
} from 'modules/purchase/pages'
import { PurchaseUpgradePDF } from 'modules/purchase/pages/PurchaseUpgradePDF'

import { IDynamicPage, TIdToPageMap } from 'models/common.model'

import { Cohort } from 'root-constants'

import { ChatBotEmail } from '../pages/chatBotEmail'
import { Download } from '../pages/download'
import {
  Email,
  EmailConsent,
  LikeChatEmail,
  LikeChatEmailConsent,
} from '../pages/email'
import { FinishingTouches } from '../pages/finishingTouches'
import {
  IntroLikeChat,
  IntroVariant1,
  IntroVariant2,
  IntroVariant3,
  IntroVariant4,
  IntroVariantRefund,
} from '../pages/intro'
import {
  Age,
  AgeAsIntroProvider,
  AgeUnisexAsIntro,
  AgingType,
  AgingTypeFeedback,
  AllRoundApproach,
  AlmostThere,
  AnalyzingAnswers,
  CareCosmetics,
  ChatStatementQuestion,
  CosmetologistVsLuvly,
  DailyWater,
  Diet,
  EatingPatterns,
  ExpertLoader,
  FaceShape,
  FaceShapeChange,
  FaceYogaExperts,
  Gender,
  GenderAsIntro,
  Goal,
  GoalVariant2,
  HeardAboutYoga,
  LikeChatAge,
  LikeChatAnalyzingAnswers,
  LikeChatCareCosmetics,
  LikeChatChallenges1,
  LikeChatChallenges2,
  LikeChatDiet,
  LikeChatEatingPatterns,
  LikeChatExercises,
  LikeChatFaceYoga,
  LikeChatGoal,
  LikeChatMentalHealth,
  LikeChatMotivation,
  LikeChatNutritionFeedback,
  LikeChatPracticeDuration,
  LikeChatProblematicAreas,
  LikeChatSkinCareRoutine,
  LikeChatSkinConcerns,
  LikeChatSkinQuality,
  LikeChatSkinType,
  LikeChatSugar,
  LikeChatVegetables,
  LuvlyGuide,
  LuvlyUsers,
  MenstrualCycle,
  MentalHealth,
  Motivation,
  NaturalFacialPractice,
  ObTags,
  Occasion,
  OccasionDate,
  OccasionResult,
  PhotoResult,
  PracticeDuration,
  PreferredFood,
  PreliminaryProgress,
  PreparePlan,
  ProblematicAreas,
  ProductIngredientFeedback,
  ReverseYourWrinkles,
  SatisfyWithSkinCondition,
  ScannerFeedback,
  ShortProgramDescription,
  ShortProgramDescriptionAsResult,
  SkinCareRoutine,
  SkinCareRoutineDescription,
  SkinConcerns,
  SkinType,
  SkinTypeExplanation,
  SkincareChallenges,
  SocialProof,
  StatementQuestion,
  Sugar,
  SunSafety,
  ThankForSharing,
  TrustFeedback,
  UselessProducts,
  Vegetables,
  VisitToCosmetologist,
  WelcomeToLuvly,
  WhatIsFaceYoga,
  WonderfulStart,
  YouAreBeautiful,
  YouAreInGoodHands,
} from '../pages/questions'
import {
  FaceScan,
  FaceScanPreview,
  FaceScanResults,
  Recommendations,
  SkinDiagnosisIntro,
} from '../pages/skinDiagnosis'

export const enum PageId {
  INTRO = 'intro',
  GOAL = 'goal',
  GOAL_VARIANT_2 = 'goalVariant2',
  HAVE_YOU_HEARD = 'haveYouHeard',
  WHAT_IS_FACE_YOGA = 'whatIsFaceYoga',
  SATISFY_WITH_SKIN_CONDITION = 'satisfyWithSkinCondition',
  SKIN_CARE_ROUTINE = 'skinCareRoutine',
  CARE_COSMETICS = 'careCosmetics',
  SUN_SAFETY = 'sunSafety',
  VISIT_TO_COSMETOLOGIST = 'visitToCosmetologist',
  COSMETOLOGIST_VS_LUVLY = 'cosmetologistVsLuvly',
  TARGET_GOAL = 'targetGoal',
  OB_TAGS = 'obTags',
  EATING_PATTERNS = 'eatingPatterns',
  DIET = 'diet',
  SUGAR = 'sugar',
  VEGETABLES = 'vegetables',
  DAILY_WATER = 'dailyWater',
  GENDER = 'gender',
  GENDER_AS_INTRO = 'genderAsIntro',
  USERS_STATEMENT = 'usersStatement',
  PHOTO_RESULT = 'photoResult',
  AGING_TYPE = 'agingType',
  AGING_TYPE_FEEDBACK = 'agingTypeFeedback',
  ALMOST_THERE = 'almostThere',
  WELCOME_TO_LUVLY = 'welcomeToLuvly',
  WONDERFUL_START = 'wonderfulStart',
  YOU_ARE_BEAUTIFUL = 'youAreBeautiful',
  THANK_FOR_SHARING = 'thankForSharing',
  NATURAL_FACIAL_PRACTICE = 'naturalFacialPractice',
  SOCIAL_PROOF = 'socialProof',
  SKINCARE_CHALLENGES = 'skincareChallenges',
  AGE = 'age',
  AGE_AS_INTRO = 'ageAsIntro',
  AGE_UNISEX_AS_INTRO = 'ageUnisexAsIntro',
  LONG_START_AGE = 'longStartAge',
  START_GOAL_VIDEO_1 = 'startGoalVideo1',
  START_GOAL_VIDEO_2 = 'startGoalVideo2',
  START_GOAL_VIDEO_3 = 'startGoalVideo3',
  START_GOAL_VIDEO_4 = 'startGoalVideo4',
  LIKE_CHAT_INTRO = 'startChat',
  INTRO_REFUND = 'introRefund',
  PROBLEMATIC_AREA = 'problematicArea',
  SKIN_TYPE = 'skinType',
  SKIN_TYPE_EXPLANATION = 'skinTypeExplanation',
  SKIN_CONCERNS = 'skinConcerns',
  MOTIVATION = 'motivation',
  SHORT_PROGRAM_DESCRIPTION = 'shortProgramDescription',
  SHORT_PROGRAM_DESCRIPTION_AS_RESULT = 'shortProgramDescriptionAsResult',
  SKIN_CARE_ROUTINE_DESCRIPTION = 'skinCareRoutineDescription',
  PREFERRED_FOOD = 'preferredFood',
  PRACTICE_DURATION = 'practiceDuration',
  FACE_SHAPE = 'faceShape',
  HELP_FROM_LUVLY = 'helpFromLuvly',
  DOUBLE_CHIN = 'doubleChin',
  SKIN_DISEASES = 'skinDiseases',
  LUVLY_RECOMMENDATIONS = 'luvlyRecommendations',
  SLEEPING_TIME = 'sleepingTime',
  FRIENDLY_REMINDER = 'friendlyReminder',
  SKIN_REQUIRES_COMMITMENT = 'skinRequiresCommitment',
  HAPPINESS_IS_HABIT = 'happinessIsHabit',
  AGING_IS_PART_OF_LIFE = 'agingIsPartOfLife',
  OWN_SKINCARE_EXPERT = 'ownSkincareExpert',
  SKIN_DIAGNOSIS_INTRO = 'skinDiagnosisIntro',
  FACE_SCAN_PREVIEW = 'faceScanPreview',
  FACE_SCAN = 'faceScan',
  FACE_SCAN_RESULTS = 'faceScanResults',
  ML_RECOMMENDATIONS = 'mlRecommendations',
  MIRROR_REFLECTION = 'mirrorReflection',
  COMPARE_MYSELF_TO_OTHERS = 'compareMyselfToOthers',
  APPEARANCE_EFFECT = 'appearanceEffect',
  AFRAID_TO_LOOK_OLDER = 'afraidToLookOlder',
  TO_BE_EXPERT = 'toBeExpert',
  COSMETICS_HELP = 'cosmeticsHelp',
  PRELIMINARY_PROGRESS = 'preliminaryProgress',
  SHORT_PROGRAM_DESCRIPTION_SECOND = 'shortProgramDescriptionSecond',
  MENSTRUAL_CYCLE = 'menstrualCycle',
  MENTAL_HEALTH = 'mentalHealth',
  PREPARE_PLAN = 'preparePlan',
  YOU_ARE_IN_GOOD_HANDS = 'youAreInGoodHands',
  FACE_YOGA_EXPERTS = 'faceYogaExperts',
  REVERSE_YOUR_WRINKLES = 'reverseYourWrinkles',
  OCCASION = 'occasion',
  OCCASION_DATE = 'occasionDate',
  OCCASION_RESULT = 'occasionResult',
  ANALYZING_ANSWERS = 'analyzingAnswers',
  NAME = 'name',
  OB_LOADER = 'obLoader',
  OB_GOAL = 'obGoal',
  OB_AGE = 'obAge',
  OB_FACE_YOGA = 'obFaceYoga',
  OB_SKIN = 'obSkin',
  OB_AREAS = 'obAreas',
  OB_SKIN_TYPE = 'obSkinType',
  OB_ROUTINE = 'obRoutine',
  OB_PRODUCTS = 'obProducts',
  OB_CHALLENGES_1 = 'obChallenges1',
  OB_CHALLENGES_2 = 'obChallenges2',
  OB_SKIN_CONCERNS = 'obSkinConcerns',
  OB_EXERCISES = 'obExercises',
  OB_MEALS = 'obMeals',
  OB_DIET = 'obDiet',
  OB_SWEETS = 'obSweets',
  OB_VEGETABLES = 'obVegetables',
  OB_NUTRITION_FEEDBACK = 'obNutritionFeedback',
  OB_MENTAL_HEALTH = 'obMentalHealth',
  OB_STATEMENT_1 = 'obStatement1',
  OB_STATEMENT_2 = 'obStatement2',
  OB_STATEMENT_3 = 'obStatement3',
  OB_MOTIVATION = 'obMotivation',
  OB_SCHEDULE = 'obSchedule',
  OB_CREATING = 'obCreating',
  OB_EMAIL = 'obEmail',
  OB_EMAIL_CONSENT = 'obEmailConsent',
  OB_RESULT = 'obResult',
  ENTER_EMAIL = 'enterEmail',
  EMAIL_CONSENT = 'emailConsent',
  PURCHASE_EMAIL = 'purchaseEmail',
  PURCHASE_VARIANT_2 = 'purchaseVariant2',
  PURCHASE_VARIANT_4 = 'purchaseVariant4',
  CANCEL_OFFER_QUESTION = 'cancelOfferQuestion',
  PURCHASE = 'purchase',
  PURCHASE_CANCEL_OFFER = 'purchaseCancelOffer',
  HOLIDAYS_PURCHASE_CANCEL_OFFER = 'holidaysPurchaseCancelOffer',
  PURCHASE_INTRO_CANCEL_OFFER = 'paywallCancelOffer',
  PURCHASE_INTRO_OFFER = 'purchaseIntroOffer',
  PURCHASE_CANCEL_REFUND = 'purchaseCancelRefund',
  PURCHASE_REFUND = 'purchaseRefund',
  PURCHASE_COSMETIC_SCANNER = 'purchaseCosmeticScanner',
  PURCHASE_UPGRADE = 'purchaseUpgrade',
  PURCHASE_UPGRADE_PDF = 'purchaseUpgradePDF',
  LIVECHAT_UPSELL_IN_APP = 'livechatUpsellInApp',
  LIVECHAT_UPSELL_CANCEL_IN_APP = 'livechatUpsellCancelInApp',
  UPSELL_PAYWALL = 'upsellPaywall',
  IN_APP_PAYWALL = 'inAppPaywall',
  IN_APP_CANCEL_PAYWALL = 'inAppCancelPaywall',
  WELLNESS_BUNDLE_PAYWALL = 'wellnessBundlePaywall',
  WELLNESS_BUNDLE_CANCEL_PAYWALL = 'wellnessBundleCancelPaywall',
  FINISHING_TOUCHES = 'finishingTouches',
  LOGIN = 'login',
  DOWNLOAD = 'download',
  UNSUBSCRIBE_LOGIN = 'unsubscribe-login',
  UNSUBSCRIBE = 'unsubscribe',
  SUBSCRIPTION_LIST_DETAILS = 'subscription-list-details',
  REASONS = 'reasons',
  CANCELING_SUBSCRIPTION = 'canceling-subscription',
  CANCELED_SUBSCRIPTION = 'canceled-subscription',
  PROXY_TO_START = 'proxyToStart',
  PAYPAL_PROXY = 'paypalProxy',
  LUVLY_USERS = 'luvlyUsers',
  LUVLY_GUIDE = 'luvlyGuide',
  ALL_ROUND_APPROACH = 'allRoundApproach',
  FACE_SHAPE_CHANGE = 'faceShapeChange',
  PRODUCT_INGREDIENT_FEEDBACK = 'productIngredientFeedback',
  USELESS_PRODUCTS = 'uselessProducts',
  SCANNER_FEEDBACK = 'scannerFeedback',
  TRUST_FEEDBACK = 'trustFeedback',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  intro: IntroVariant1,
  introRefund: IntroVariantRefund,
  luvlyUsers: LuvlyUsers,
  luvlyGuide: LuvlyGuide,
  allRoundApproach: AllRoundApproach,
  haveYouHeard: HeardAboutYoga,
  whatIsFaceYoga: WhatIsFaceYoga,
  satisfyWithSkinCondition: SatisfyWithSkinCondition,
  skinCareRoutine: SkinCareRoutine,
  careCosmetics: CareCosmetics,
  sunSafety: SunSafety,
  trustFeedback: TrustFeedback,
  visitToCosmetologist: VisitToCosmetologist,
  cosmetologistVsLuvly: CosmetologistVsLuvly,
  eatingPatterns: EatingPatterns,
  diet: Diet,
  sugar: Sugar,
  vegetables: Vegetables,
  dailyWater: DailyWater,
  gender: Gender,
  almostThere: AlmostThere,
  welcomeToLuvly: WelcomeToLuvly,
  wonderfulStart: WonderfulStart,
  youAreBeautiful: YouAreBeautiful,
  thankForSharing: ThankForSharing,
  naturalFacialPractice: NaturalFacialPractice,
  socialProof: SocialProof,
  skincareChallenges: SkincareChallenges,
  genderAsIntro: GenderAsIntro,
  photoResult: PhotoResult,
  agingType: AgingType,
  productIngredientFeedback: ProductIngredientFeedback,
  age: Age,
  ageAsIntro: AgeAsIntroProvider,
  ageUnisexAsIntro: AgeUnisexAsIntro,
  agingTypeFeedback: AgingTypeFeedback,
  longStartAge: IntroVariant3,
  startGoalVideo1: IntroVariant4,
  startGoalVideo2: IntroVariant4,
  startGoalVideo3: IntroVariant4,
  startGoalVideo4: IntroVariant4,
  startChat: IntroLikeChat,
  uselessProducts: UselessProducts,
  goalVariant2: GoalVariant2,
  problematicArea: ProblematicAreas,
  skinType: SkinType,
  skinTypeExplanation: SkinTypeExplanation,
  skinConcerns: SkinConcerns,
  motivation: Motivation,
  shortProgramDescription: ShortProgramDescription,
  shortProgramDescriptionAsResult: ShortProgramDescriptionAsResult,
  skinCareRoutineDescription: SkinCareRoutineDescription,
  preferredFood: PreferredFood,
  practiceDuration: PracticeDuration,
  faceShape: FaceShape,
  faceShapeChange: FaceShapeChange,
  skinDiagnosisIntro: SkinDiagnosisIntro,
  faceScanPreview: FaceScanPreview,
  faceScan: FaceScan,
  faceScanResults: FaceScanResults,
  scannerFeedback: ScannerFeedback,
  mlRecommendations: Recommendations,
  mirrorReflection: StatementQuestion,
  skinRequiresCommitment: StatementQuestion,
  compareMyselfToOthers: StatementQuestion,
  appearanceEffect: StatementQuestion,
  afraidToLookOlder: StatementQuestion,
  toBeExpert: StatementQuestion,
  cosmeticsHelp: StatementQuestion,
  happinessIsHabit: StatementQuestion,
  obLoader: ExpertLoader,
  obGoal: LikeChatGoal,
  obAge: LikeChatAge,
  obFaceYoga: LikeChatFaceYoga,
  obSkin: LikeChatSkinQuality,
  obAreas: LikeChatProblematicAreas,
  obExercises: LikeChatExercises,
  obSkinType: LikeChatSkinType,
  obRoutine: LikeChatSkinCareRoutine,
  obProducts: LikeChatCareCosmetics,
  obChallenges1: LikeChatChallenges1,
  obChallenges2: LikeChatChallenges2,
  obSkinConcerns: LikeChatSkinConcerns,
  obMeals: LikeChatEatingPatterns,
  obDiet: LikeChatDiet,
  obSweets: LikeChatSugar,
  obVegetables: LikeChatVegetables,
  obNutritionFeedback: LikeChatNutritionFeedback,
  obMentalHealth: LikeChatMentalHealth,
  obStatement1: ChatStatementQuestion,
  obStatement2: ChatStatementQuestion,
  obStatement3: ChatStatementQuestion,
  obMotivation: LikeChatMotivation,
  obSchedule: LikeChatPracticeDuration,
  obCreating: LikeChatAnalyzingAnswers,
  preliminaryProgress: PreliminaryProgress,
  shortProgramDescriptionSecond: ShortProgramDescription,
  menstrualCycle: MenstrualCycle,
  mentalHealth: MentalHealth,
  preparePlan: PreparePlan,
  youAreInGoodHands: YouAreInGoodHands,
  faceYogaExperts: FaceYogaExperts,
  reverseYourWrinkles: ReverseYourWrinkles,
  occasion: Occasion,
  obTags: ObTags,
  occasionDate: OccasionDate,
  occasionResult: OccasionResult,
  analyzingAnswers: AnalyzingAnswers,
  obEmail: LikeChatEmail,
  obEmailConsent: LikeChatEmailConsent,
  enterEmail: Email,
  emailConsent: EmailConsent,
  chatBotEmail: ChatBotEmail,
  purchaseEmail: PurchaseVariant2,
  purchaseVariant2: PurchaseVariant2,
  purchaseVariant4: PurchaseVariant4,
  purchaseCosmeticScanner: PurchaseCosmeticScanner,
  purchaseRefund: PurchaseRefund,
  purchaseCancelRefund: PurchaseRefund,
  cancelOfferQuestion: CancelOfferQuestion,
  purchaseCancelOffer: PurchaseCancelOffer,
  holidaysPurchaseCancelOffer: HolidaysPurchaseCancelOffer,
  paywallCancelOffer: PurchaseVariant7,
  purchaseIntroOffer: PurchaseVariant7,
  purchaseUpgrade: PurchaseUpgrade,
  purchaseUpgradePDF: PurchaseUpgradePDF,
  upsellPaywall: UpsellPaywallSwitchers,
  wellnessBundlePaywall: UpsellWellnessBundle,
  wellnessBundleCancelPaywall: UpsellWellnessBundle,
  inAppPaywall: UpsellGuasha,
  inAppCancelPaywall: UpsellGuasha,
  finishingTouches: FinishingTouches,
  login: Login,
  download: Download,
}

export const ID_TO_PAGE_MAP_V3: TIdToPageMap = {
  ...ID_TO_PAGE_MAP,
  intro: IntroVariant2,
  goal: Goal,
  purchaseVariant4: PurchaseVariant4,
}

export const DYNAMIC_PAGES_SET_VARIANT_1: IDynamicPage[] = [
  { id: PageId.INTRO },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGE },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_2: IDynamicPage[] = [
  { id: PageId.INTRO_REFUND },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGE },
  { id: PageId.AGING_TYPE },
  { id: PageId.AGING_TYPE_FEEDBACK },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_5: IDynamicPage[] = [
  { id: PageId.INTRO },
  { id: PageId.GOAL },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGE },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_7: IDynamicPage[] = [
  { id: PageId.AGE_AS_INTRO },
  { id: PageId.GOAL_VARIANT_2 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGING_TYPE },
  { id: PageId.AGING_TYPE_FEEDBACK },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_8: IDynamicPage[] = [
  { id: PageId.INTRO },
  { id: PageId.LUVLY_USERS },
  { id: PageId.GENDER, hasProgressBar: true, progressValue: 1 },
  { id: PageId.AGE, hasProgressBar: true, progressValue: 2 },
  { id: PageId.SKIN_TYPE, hasProgressBar: true, progressValue: 3 },
  {
    id: PageId.SATISFY_WITH_SKIN_CONDITION,
    hasProgressBar: true,
    progressValue: 4,
  },
  { id: PageId.SKIN_CONCERNS, hasProgressBar: true, progressValue: 5 },
  { id: PageId.LUVLY_GUIDE },
  { id: PageId.SKIN_CARE_ROUTINE, hasProgressBar: true, progressValue: 6 },
  { id: PageId.CARE_COSMETICS, hasProgressBar: true, progressValue: 7 },
  { id: PageId.SUN_SAFETY, hasProgressBar: true, progressValue: 8 },
  { id: PageId.PRODUCT_INGREDIENT_FEEDBACK },
  { id: PageId.USELESS_PRODUCTS, hasProgressBar: true, progressValue: 9 },
  { id: PageId.SCANNER_FEEDBACK },
  { id: PageId.TRUST_FEEDBACK },
  { id: PageId.FACE_SHAPE_CHANGE },
  { id: PageId.PROBLEMATIC_AREA, hasProgressBar: true, progressValue: 10 },
  { id: PageId.MOTIVATION, hasProgressBar: true, progressValue: 11 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.ALL_ROUND_APPROACH },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.SKIN_REQUIRES_COMMITMENT },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.TO_BE_EXPERT },
  { id: PageId.HAPPINESS_IS_HABIT },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PREPARE_PLAN },
  {
    id: PageId.SHORT_PROGRAM_DESCRIPTION,
    hasProgressBar: true,
    progressValue: 12,
  },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_9: IDynamicPage[] = [
  { id: PageId.AGE_UNISEX_AS_INTRO },
  { id: PageId.GOAL_VARIANT_2 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGING_TYPE },
  { id: PageId.AGING_TYPE_FEEDBACK },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_10: IDynamicPage[] = [
  { id: PageId.AGE_AS_INTRO },
  { id: PageId.GOAL_VARIANT_2 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGING_TYPE },
  { id: PageId.AGING_TYPE_FEEDBACK },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.OCCASION },
  { id: PageId.OCCASION_DATE },
  { id: PageId.OCCASION_RESULT },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_11: IDynamicPage[] = [
  { id: PageId.LONG_START_AGE },
  { id: PageId.GOAL_VARIANT_2 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_12: IDynamicPage[] = [
  { id: PageId.GENDER_AS_INTRO },
  { id: PageId.GOAL_VARIANT_2 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGE },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_13: IDynamicPage[] = [
  { id: PageId.AGE_AS_INTRO },
  {
    id: PageId.GENDER,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 2,
    hasMinimalisticHeader: true,
  },
  { id: PageId.SOCIAL_PROOF, hasMinimalisticHeader: true },
  { id: PageId.WELCOME_TO_LUVLY, hasMinimalisticHeader: true },
  {
    id: PageId.GOAL_VARIANT_2,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 5,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.OB_TAGS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 6,
    hasMinimalisticHeader: true,
  },
  { id: PageId.WONDERFUL_START, hasMinimalisticHeader: true },
  {
    id: PageId.HAVE_YOU_HEARD,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 8,
    hasMinimalisticHeader: true,
  },
  { id: PageId.NATURAL_FACIAL_PRACTICE, hasMinimalisticHeader: true },
  {
    id: PageId.FACE_SHAPE,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 10,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.PROBLEMATIC_AREA,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 11,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SATISFY_WITH_SKIN_CONDITION,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 12,
    hasMinimalisticHeader: true,
  },
  { id: PageId.YOU_ARE_BEAUTIFUL, hasMinimalisticHeader: true },
  {
    id: PageId.SKIN_TYPE,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 14,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKIN_TYPE_EXPLANATION,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 14,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKIN_CONCERNS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 15,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKIN_CARE_ROUTINE,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 16,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.CARE_COSMETICS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 17,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKINCARE_CHALLENGES,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 18,
    hasMinimalisticHeader: true,
  },
  { id: PageId.THANK_FOR_SHARING, hasMinimalisticHeader: true },
  {
    id: PageId.EATING_PATTERNS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 20,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.DIET,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 21,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SUGAR,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 22,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.VEGETABLES,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 23,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.PREFERRED_FOOD,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 24,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.DAILY_WATER,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 25,
    hasMinimalisticHeader: true,
  },
  { id: PageId.ALMOST_THERE, hasMinimalisticHeader: true },
  {
    id: PageId.MENTAL_HEALTH,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 27,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKIN_REQUIRES_COMMITMENT,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 28,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.COMPARE_MYSELF_TO_OTHERS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 29,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.TO_BE_EXPERT,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 30,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.HAPPINESS_IS_HABIT,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 31,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.COSMETICS_HELP,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 32,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.MOTIVATION,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 33,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.PRACTICE_DURATION,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 34,
    hasMinimalisticHeader: true,
  },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

const COHORT_WITH_START_VIDEO_PAGES_SET: IDynamicPage[] = [
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGE },
  { id: PageId.AGING_TYPE },
  { id: PageId.AGING_TYPE_FEEDBACK },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_14: IDynamicPage[] = [
  { id: PageId.START_GOAL_VIDEO_1 },
  ...COHORT_WITH_START_VIDEO_PAGES_SET,
]

export const DYNAMIC_PAGES_SET_VARIANT_16: IDynamicPage[] = [
  { id: PageId.START_GOAL_VIDEO_3 },
  ...COHORT_WITH_START_VIDEO_PAGES_SET,
]

export const DYNAMIC_PAGES_SET_VARIANT_17: IDynamicPage[] = [
  { id: PageId.START_GOAL_VIDEO_4 },
  ...COHORT_WITH_START_VIDEO_PAGES_SET,
]

export const DYNAMIC_PAGES_SET_VARIANT_18: IDynamicPage[] = [
  { id: PageId.LIKE_CHAT_INTRO },
  { id: PageId.OB_LOADER },
  { id: PageId.OB_GOAL, hasProgressBar: true, progressValue: 3 },
  { id: PageId.OB_AGE, hasProgressBar: true, progressValue: 4 },
  { id: PageId.OB_FACE_YOGA, hasProgressBar: true, progressValue: 5 },
  { id: PageId.OB_SKIN, hasProgressBar: true, progressValue: 6 },
  { id: PageId.OB_AREAS, hasProgressBar: true, progressValue: 7 },
  { id: PageId.OB_EXERCISES, hasProgressBar: true, progressValue: 8 },
  { id: PageId.OB_SKIN_TYPE, hasProgressBar: true, progressValue: 9 },
  { id: PageId.OB_SKIN_CONCERNS, hasProgressBar: true, progressValue: 10 },
  { id: PageId.OB_ROUTINE, hasProgressBar: true, progressValue: 11 },
  { id: PageId.OB_PRODUCTS, hasProgressBar: true, progressValue: 12 },
  { id: PageId.OB_CHALLENGES_1, hasProgressBar: true, progressValue: 13 },
  { id: PageId.OB_CHALLENGES_2, hasProgressBar: true, progressValue: 13 },
  { id: PageId.OB_MEALS, hasProgressBar: true, progressValue: 14 },
  { id: PageId.OB_DIET, hasProgressBar: true, progressValue: 15 },
  { id: PageId.OB_SWEETS, hasProgressBar: true, progressValue: 16 },
  { id: PageId.OB_VEGETABLES, hasProgressBar: true, progressValue: 17 },
  { id: PageId.OB_NUTRITION_FEEDBACK, hasProgressBar: true, progressValue: 18 },
  { id: PageId.OB_MENTAL_HEALTH, hasProgressBar: true, progressValue: 19 },
  { id: PageId.OB_STATEMENT_1, hasProgressBar: true, progressValue: 20 },
  { id: PageId.OB_STATEMENT_2, hasProgressBar: true, progressValue: 21 },
  { id: PageId.OB_STATEMENT_3, hasProgressBar: true, progressValue: 22 },
  { id: PageId.OB_MOTIVATION, hasProgressBar: true, progressValue: 23 },
  { id: PageId.OB_SCHEDULE, hasProgressBar: true, progressValue: 24 },
  { id: PageId.OB_CREATING, hasProgressBar: true, progressValue: 25 },
  { id: PageId.OB_EMAIL, hasProgressBar: true, progressValue: 26 },
]

export const DYNAMIC_PAGES_MAP = {
  [Cohort.QUIZ_2]: [DYNAMIC_PAGES_SET_VARIANT_1],
  [Cohort.LUVLY_INTRO_FAST]: [
    DYNAMIC_PAGES_SET_VARIANT_7,
    DYNAMIC_PAGES_SET_VARIANT_10,
  ],
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_MARISSA]: [DYNAMIC_PAGES_SET_VARIANT_5],
  [Cohort.LUVLY_COSMETIC_SCANNER]: [DYNAMIC_PAGES_SET_VARIANT_8],
  [Cohort.LUVLY_9]: [DYNAMIC_PAGES_SET_VARIANT_11],
  [Cohort.LUVLY_10]: [DYNAMIC_PAGES_SET_VARIANT_12],
  [Cohort.LUVLY_12]: [DYNAMIC_PAGES_SET_VARIANT_13],
  [Cohort.LUVLY_INTRO2T]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_14]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_15]: [DYNAMIC_PAGES_SET_VARIANT_14],
  [Cohort.LUVLY_17]: [DYNAMIC_PAGES_SET_VARIANT_16],
  [Cohort.LUVLY_18]: [DYNAMIC_PAGES_SET_VARIANT_17],
  [Cohort.LUVLY_CHAT]: [DYNAMIC_PAGES_SET_VARIANT_18],
  [Cohort.LUVLY_19]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_INTRO_SALE]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_INTRO3_SALE]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_12_SALE]: [DYNAMIC_PAGES_SET_VARIANT_13],
  [Cohort.LUVLY_20]: [DYNAMIC_PAGES_SET_VARIANT_9],
  [Cohort.LUVLY_REF]: [DYNAMIC_PAGES_SET_VARIANT_2],
}

export const EMAIL_CONSENT_PAGES_MAP: Record<string, PageId> = {
  [Cohort.QUIZ_2]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_EMAIL_7]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_INTRO_FAST]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_EMAIL_8]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_MARISSA]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_COSMETIC_SCANNER]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_9]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_10]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_12]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_INTRO2T]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_14]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_CHAT]: PageId.OB_EMAIL_CONSENT,
  [Cohort.LUVLY_19]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_20]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_REF]: PageId.EMAIL_CONSENT,
}

export const EMAIL_CONSENT_PAGES: { id: PageId }[] = [
  { id: PageId.EMAIL_CONSENT },
  { id: PageId.OB_EMAIL_CONSENT },
]

export const PURCHASE_PAGES: { id: PageId }[] = [
  { id: PageId.PURCHASE_EMAIL },
  { id: PageId.PURCHASE_VARIANT_2 },
  { id: PageId.CANCEL_OFFER_QUESTION },
  { id: PageId.PURCHASE_CANCEL_OFFER },
  { id: PageId.PURCHASE_INTRO_OFFER },
  { id: PageId.HOLIDAYS_PURCHASE_CANCEL_OFFER },
  { id: PageId.PURCHASE_INTRO_CANCEL_OFFER },
  { id: PageId.PURCHASE_VARIANT_4 },
  { id: PageId.PURCHASE_COSMETIC_SCANNER },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_AS_RESULT },
  { id: PageId.PURCHASE_REFUND },
  { id: PageId.PURCHASE_CANCEL_REFUND },
]

export const PURCHASE_PAGES_MAP: Record<Cohort, PageId> = {
  [Cohort.QUIZ_2]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_EMAIL_7]: PageId.PURCHASE_EMAIL,
  [Cohort.LUVLY_INTRO_FAST]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_CHAT_UPGRADE_6_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_6_EX_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_9_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_12_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_12_EX_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_24_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_EMAIL_8]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: PageId.PURCHASE_INTRO_CANCEL_OFFER,
  [Cohort.LUVLY_MARISSA]: PageId.PURCHASE_VARIANT_4,
  [Cohort.LUVLY_COSMETIC_SCANNER]: PageId.PURCHASE_COSMETIC_SCANNER,
  [Cohort.LUVLY_9]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_10]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_12]: PageId.SHORT_PROGRAM_DESCRIPTION_AS_RESULT,
  [Cohort.LUVLY_INTRO2T]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_14]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_15]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_17]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_18]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_CHAT]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_19]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_INTRO_SALE]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_INTRO3_SALE]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_12_SALE]: PageId.SHORT_PROGRAM_DESCRIPTION_AS_RESULT,
  [Cohort.LUVLY_20]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_REF]: PageId.PURCHASE_REFUND,
}

export const UPSELL_PAGES_MAP: Record<Cohort, PageId[]> = {
  [Cohort.QUIZ_2]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_EMAIL_7]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_INTRO_FAST]: [
    PageId.UPSELL_PAYWALL,
    PageId.IN_APP_PAYWALL,
    PageId.IN_APP_CANCEL_PAYWALL,
  ],
  [Cohort.LUVLY_EMAIL_8]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_MARISSA]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_9]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_10]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_12]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_INTRO2T]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_14]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_15]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_17]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_18]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_19]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_20]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_REF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_12_SALE]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_INTRO_SALE]: [
    PageId.UPSELL_PAYWALL,
    PageId.IN_APP_PAYWALL,
    PageId.IN_APP_CANCEL_PAYWALL,
  ],
  [Cohort.LUVLY_INTRO3_SALE]: [
    PageId.UPSELL_PAYWALL,
    PageId.IN_APP_PAYWALL,
    PageId.IN_APP_CANCEL_PAYWALL,
  ],
  [Cohort.LUVLY_CHAT_UPGRADE_6_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_6_EX_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_12_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_12_EX_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_9_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_COSMETIC_SCANNER]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_24_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE]: [PageId.UPSELL_PAYWALL],
}
