import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import badgeImg from 'assets/images/sprite/badge.svg'

import { Locale } from 'root-constants'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuarantee.styles'

export const MoneyBackGuarantee: React.FC = () => {
  const { t } = useTranslation()
  const { isWellnessBundleCohort } = useActiveCohortIdentifier()
  const language = useSelector(selectLanguage)

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`purchase1.moneyBackGuarantee.title`}</S.Title>
        <S.Text
          isEnLocaleStyle={
            language === Locale.ENGLISH && !isWellnessBundleCohort
          }
        >
          <Trans i18nKey="purchase1.moneyBackGuarantee.text" />{' '}
          <TermsOfUseLink />
        </S.Text>
        <S.Badge svg={badgeImg} />
      </S.Content>
    </S.Wrapper>
  )
}
