import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  INTRO_SALES_COHORTS_DISCOUNT_MAP,
  SUBSCRIPTION_DISCOUNTS,
} from 'modules/purchase/constants'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { StyledCancelOfferDiscountInfo as S } from './CancelOfferDiscountInfo.styles'

export const CancelOfferDiscountInfo = () => {
  const { isDynamicDiscountCohort } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const prevDiscount = useMemo(() => {
    if (isDynamicDiscountCohort) {
      return (
        dynamicDiscount?.amount ||
        INTRO_SALES_COHORTS_DISCOUNT_MAP[cohortToUse] ||
        SUBSCRIPTION_DISCOUNTS.DEFAULT
      )
    }

    return SUBSCRIPTION_DISCOUNTS.DEFAULT
  }, [cohortToUse, dynamicDiscount, isDynamicDiscountCohort])

  return (
    <S.Root data-testid="cancel-offer-container">
      <Trans
        i18nKey="purchase7.cancelOfferDiscount"
        components={[<i />, <strong />]}
        values={{
          discount: prevDiscount + CANCEL_OFFER_ADDITIONAL_DISCOUNT,
          prevDiscount,
        }}
      />
    </S.Root>
  )
}
