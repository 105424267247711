import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
} from 'modules/purchase/redux/selects/common'

import { StyledUpsellDisclaimer as S } from './UpsellDisclaimer.styles'

export const UpsellDisclaimer: React.FC = () => {
  const { t } = useTranslation()
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)
  const contactFormLink = getSupportFormLink()

  return (
    <S.Wrapper>
      <Trans
        i18nKey="upsellWithTimerExclusive.disclaimer"
        values={{
          currentPrice,
          currency: CURRENCY_SYMBOLS[currency],
          context: currency,
        }}
        components={[<br />]}
      />{' '}
      <TermsOfUseLink /> {t`commonComponents.or`}{' '}
      <a target="_blank" rel="noopener noreferrer" href={contactFormLink}>
        {t`commonComponents.contactSupportDisclaimer`}
      </a>
    </S.Wrapper>
  )
}
