import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'
import 'firebase/auth'

import { Button } from 'components/Button'
import { DomainsContainer } from 'components/DomainsContainer'
import { ErrorNotification } from 'components/ErrorNotification'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import { selectError, selectIsFetching } from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { usePasswordInputField } from 'hooks/usePasswordInputField'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import {
  IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY,
  SignupOption,
} from 'modules/login/constants'
import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from 'modules/login/redux'

import { eventLogger } from 'services/eventLogger.service'

import { StyledLoginButton } from 'common-styles'
import {
  EMAIL_DOMAINS,
  EMAIL_DOMAIN_REGEXP,
  EMAIL_USERNAME_REGEXP,
  LoginMethod,
  PlatformOS,
} from 'root-constants'

import { StyledLogin as S } from './Login.styles'
import { Disclaimer } from './components/Disclaimer'
import { EmailLogin } from './components/EmailLogin'

export const Login: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const uuid = useSelector(selectUUID)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const userOnboardingEmail = useSelector(selectUserOnboardingEmail)

  const [isModalShown, setIsModalShown] = useState(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)
  const [signupOption, setSignupOption] = useState(SignupOption.EMAIL)
  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)

  const [email, setEmail] = useEmailInputField(emailErrorMessage, () =>
    dispatch(resetEmailErrorMessageAction()),
  )
  const [password, setPassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )

  const deferredEmail = useDeferredValue(email.value)

  const domainsList = useMemo(() => {
    const [, emailDomain] = EMAIL_DOMAIN_REGEXP.exec(deferredEmail) || []
    const [userName] = EMAIL_USERNAME_REGEXP.exec(deferredEmail) || []
    return EMAIL_DOMAINS.filter((domain) => domain.includes(emailDomain)).map(
      (filteredDomain) => `${userName}${filteredDomain}`,
    )
  }, [deferredEmail])

  const isAndroid = useMemo(
    () => getMobileOperatingSystem() === PlatformOS.ANDROID,
    [],
  )

  const isLoginViaEmailActive = useMemo(
    () => signupOption === SignupOption.EMAIL,
    [signupOption],
  )

  const errorText = useMemo(() => {
    if (!email.isValid && !password.isValid) return t`login.invalidFields`
    if (!email.isValid && !domainsList.length) {
      return email.firebaseError || email.validationText
    }
    if (!password.isValid) {
      return password.firebaseError || password.validationText
    }

    return ''
  }, [
    t,
    email.isValid,
    email.validationText,
    email.firebaseError,
    password.isValid,
    password.validationText,
    password.firebaseError,
    domainsList,
  ])

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '',
    [email.isValid, email.value, password.isValid, password.value],
  )

  const signInFirebaseMethod = useCallback(
    (provider) => firebase.auth().signInWithRedirect(provider),
    [],
  )

  useEffect(() => {
    const isAccountVisited = JSON.parse(
      sessionStorage.getItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY) || 'false',
    )

    if (isAccountVisited) return
    eventLogger.logCreateAccountShown()

    sessionStorage.setItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY, 'true')
  }, [])

  useEffect(() => {
    setEmail((prevState) => ({ ...prevState, value: userOnboardingEmail }))
  }, [setEmail, userOnboardingEmail])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  const handleSignInFirebase = useCallback(
    async (provider) => {
      try {
        await signInFirebaseMethod(provider)
      } catch (err) {
        Sentry.captureMessage(
          `Firebase login failed. User: ${uuid}, provider: ${provider}, error: ${err}`,
        )
      }
    },
    [signInFirebaseMethod, uuid],
  )

  const handleContinueWithApple = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.APPLE })

    await handleSignInFirebase(new firebase.auth.OAuthProvider('apple.com'))
  }, [handleSignInFirebase])

  const handleContinueWithEmail = useCallback(
    (e) => {
      e.preventDefault()

      eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
      dispatch(
        registerWithEmailFirebaseAction({
          email: email.value,
          password: password.value,
        }),
      )
    },
    [dispatch, email.value, password.value],
  )

  const handleButtonsClick = useCallback(
    (event) => {
      if (error) {
        event.stopPropagation()
        setIsModalShown(true)
      }
    },
    [error],
  )

  const showOtherSignupOptions = useCallback(() => {
    setPassword((prevState) => ({ ...prevState, value: '' }))
    setSignupOption(SignupOption.OTHER_OPTIONS)

    eventLogger.logOtherSignupOptionsShown()
  }, [setPassword])

  const showSignupWithEmail = useCallback(() => {
    setSignupOption(SignupOption.EMAIL)
    eventLogger.logSignupWithEmailShown()
  }, [])

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  const handlePrefilledEmail = useCallback(
    ({ target }) => {
      setAreEmailTipsVisible(false)
      setEmail((prevState) => ({
        ...prevState,
        value: target.value,
      }))
    },
    [setEmail],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <S.Wrapper>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}

      <div>
        <S.Title>{t`login.title`}</S.Title>

        {isLoginViaEmailActive && (
          <form onSubmit={handleContinueWithEmail}>
            <S.InputContainer>
              <EmailLogin
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                setAreEmailTipsVisible={setAreEmailTipsVisible}
              />

              {areEmailTipsVisible && (
                <DomainsContainer>
                  {domainsList.map((item) => (
                    <button
                      type="button"
                      key={item}
                      value={item}
                      onClick={handlePrefilledEmail}
                    >
                      {item}
                    </button>
                  ))}
                </DomainsContainer>
              )}
            </S.InputContainer>
            <S.EmailLoginContainer>
              {!isAndroid && (
                <S.SignUpLink marginBottom={0} onClick={showOtherSignupOptions}>
                  <Trans
                    i18nKey="login.signupOptions"
                    components={[<strong />]}
                  />
                </S.SignUpLink>
              )}

              <S.DisclaimerContainer>
                <ErrorNotification errorText={errorText} />
                <Disclaimer />
              </S.DisclaimerContainer>
              <Button type="submit" disabled={!isComplete || isFetching}>
                {t`actions.continue`}
              </Button>
            </S.EmailLoginContainer>
          </form>
        )}

        {!isLoginViaEmailActive && (
          <>
            <S.ButtonsContainer onClickCapture={handleButtonsClick}>
              {!isAndroid && (
                <StyledLoginButton.Apple onClick={handleContinueWithApple}>
                  {t`actions.continueWithApple`}
                </StyledLoginButton.Apple>
              )}
            </S.ButtonsContainer>
            <S.SignUpLink marginBottom={42} onClick={showSignupWithEmail}>
              <Trans
                i18nKey="login.signupWithEmail"
                components={[<strong />]}
              />
            </S.SignUpLink>
            <Disclaimer />
          </>
        )}
      </div>

      <Modal
        onClose={() => {
          setIsModalShown(false)
          dispatch(resetErrorAction())
        }}
        isShown={isModalShown}
        error={error}
      />
    </S.Wrapper>
  )
}
