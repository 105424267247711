/* eslint-disable max-lines */
import { PageId } from './pages'

export const LIVE_CHAT_PUBLIC_KEY = '10826272'
export const FREE_USER_GROUP_ID = '69'
export const PAID_USER_GROUP_ID = '23'

export const enum CurrentEnvironment {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 415,
  TABLET = 520,
}

export const enum CardPaymentFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const SUPPORT_FORM_LINKS_MAP = {
  [CurrentEnvironment.PROD]: 'https://account.luvly.care/contact-form',
  [CurrentEnvironment.STAGE]: 'https://account-luvly.gistage.com/contact-form',
  [CurrentEnvironment.DEV]: 'https://account-luvly.gidev.xyz/contact-form',
}
export const TERMS_OF_USE_LINK = 'https://luvly.care/terms-of-use'
export const PRIVACY_POLICY_LINK = 'https://luvly.care/privacy-policy'
export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/34486912bbc40fd28752c59e42098ae09101436d76566d91d767d95811286b02'

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const EMAIL_FROM_URL_REGEXP = /email=([^&]*)/
export const EMAIL_DOMAIN_REGEXP = /@(.*)/
export const EMAIL_USERNAME_REGEXP = /(.*)@/

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.GOOGLE]: LoginMethod.GOOGLE,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const LOGIN_METHOD_INSTRUCTION_LINK_MAP = {
  [LoginMethod.EMAIL]:
    'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Password&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dpassword',
  [LoginMethod.GOOGLE]:
    'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Google&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dgoogle.com',
  [LoginMethod.FACEBOOK]:
    'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Facebook&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dfacebook.com',
  [LoginMethod.APPLE]:
    'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Apple&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dapple.com',
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum FirebaseSignInErrorCode {
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_NOT_FOUND = 'auth/user-not-found',
  USER_DISABLED = 'auth/user-disabled',
  INVALID_EMAIL = 'auth/invalid-email',
}

export const enum Color {
  PRIMARY = '#ff8276',
  SUCCESS = '#7ad718',
  ERROR = '#ea5873',
  ERROR_SECONDARY = '#e74c3c',
  WHITE = '#fff',
  SANDY = '#fbf8f3',
  DARK = '#2d3240',
  DARK_SECONDARY = '#2c3131',
  LIGHT = '#81848c',
  BLUE_DARK = '#17202a',
  BLUE_GRAY = '#4C4C4C',
  MIDNIGHT_BLUE = '#292d3d',
  GRAY = '#626262',
  CORAL_GRAY = '#f2f3f5',
  GRAY_SECONDARY = '#aaacb2',
  GRAY_LIGHT = '#e9e9e9',
  SILVER_GRAY = '#dadadd',
  GREEN = '#628f7b',
  GREEN_LIGHT = '#d0ddd7',
  RED = '#e85e47',
  LIGHT_PINK = '#febfba',
  LIGHT_PURPLE = '#c5d1ff',
  DEFAULT_BACKGROUND = '#fdfdff',
  CREAMY = '#fae0d5',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum UserAgent {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  OTHER = 'other',
}

export const enum Cohort {
  QUIZ_2 = 'quiz_2',
  LUVLY_EMAIL_7 = 'luvly_email_7',
  LUVLY_INTRO_FAST = 'luvly_intro_fast',
  LUVLY_CHAT_UPGRADE_6_PDF = 'luvly_chat_upgrade6_pdf',
  LUVLY_CHAT_UPGRADE_6_EX_PDF = 'luvly_chat_upgrade6ex_pdf',
  LUVLY_CHAT_UPGRADE_9_PDF = 'luvly_chat_upgrade9_pdf',
  LUVLY_CHAT_UPGRADE_12_PDF = 'luvly_chat_upgrade12_pdf',
  LUVLY_CHAT_UPGRADE_12_EX_PDF = 'luvly_chat_upgrade12ex_pdf',
  LUVLY_CHAT_UPGRADE_24_PDF = 'luvly_chat_upgrade24_pdf',
  LUVLY_CHAT_UPGRADE_BUNDLE_PDF = 'luvly_chat_upgrade_bundle_pdf',
  LUVLY_CHAT_GUASHA = 'livechat_guasha',
  LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE = 'luvly_chat_upgrade_wellness_bundle',
  LUVLY_EMAIL_8 = 'luvly_email_8',
  LUVLY_EMAIL_INTRO_FAST = 'luvly_email_intro_fast',
  LUVLY_CANCEL_INTRO_FAST = 'luvly_cancel_intro_fast',
  LUVLY_MARISSA = 'luvly_marissa',
  LUVLY_COSMETIC_SCANNER = 'luvly_cosmetic_scanner',
  LUVLY_9 = 'luvly_9',
  LUVLY_10 = 'luvly_10',
  LUVLY_12 = 'luvly_12',
  LUVLY_INTRO2T = 'luvly_intro2t',
  LUVLY_14 = 'luvly_14',
  LUVLY_15 = 'luvly_15',
  LUVLY_17 = 'luvly_17',
  LUVLY_18 = 'luvly_18',
  LUVLY_CHAT = 'luvly_chat',
  LUVLY_19 = 'luvly_19',
  LUVLY_INTRO_SALE = 'luvly_intro_sale',
  LUVLY_INTRO3_SALE = 'luvly_intro3_sale',
  LUVLY_12_SALE = 'luvly_12_sale',
  LUVLY_20 = 'luvly_20',
}

export const INTRO_EMAIL_COHORTS: Cohort[] = [
  Cohort.LUVLY_EMAIL_INTRO_FAST,
  Cohort.LUVLY_CANCEL_INTRO_FAST,
]

export const enum PagePath {
  LOCALHOST = 'localhost',
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const ALTERNATIVE_GENDER = 'other'

export const enum UserGoal {
  WRINKLE_FREE_SKIN = 'get_rid_of_wrinkles',
  REDUCE_FACE_FAT = 'reduce_face_fat',
  BOTH_OPTIONS = 'both_options',
}

export const enum UserAge {
  TWENTY = '20', // 18-29
  THIRTY = '30', // 30-39
  FOURTY = '40', // 40-49
  FIFTY = '50', // 50-59
  SIXTY = '60', // 60-69
  SEVENTY = '70', // 70+
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPGRADE = 'upgrade',
  UPSELL = 'upsell',
  ONE_TIME = 'one_time',
}

export const enum SubscriptionTagsType {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
  USD = 'us',
  WORLDWIDE = 'ww',
  AGE_30_59 = 'age_30_59',
  GUASHA = 'guasha',
  GUASHA_CANCEL = 'guasha_cancel',
  LIVECHAT_GUASHA = 'livechat_guasha',
  LIVECHAT_GUASHA_CANCEL = 'livechat_guasha_cancel',
  WELLNESS_BUNDLE = 'bundle',
  WELLNESS_BUNDLE_CANCEL = 'cancel_bundle',
  AI_PRODUCTS = 'ai',
  HIGH_PRICES = '576_prices',
}

export const enum OptionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export const enum UserAnswer {
  YES = 'yes',
  NO = 'no',
}

export const enum Country {
  USA = 'US',
  CANADA = 'CA',
}

export const enum LiveChatStatus {
  MAXIMIZED = 'maximized',
  MINIMIZED = 'minimized',
  HIDDEN = 'hidden',
}

export const enum Locale {
  ENGLISH = 'en',
  FRENCH = 'fr',
  SPANISH = 'es',
  ITALIAN = 'it',
}

export const APP_NAME = 'facetory'
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const DATES_NUMBER_FOR_ANIMATION = 8
export const PAGE_TRANSITION_DELAY = 500
export const CHAT_PAGE_TRANSITION_DELAY = 1500
export const ONBOARDING_INPUT_MIN_LENGTH = 5
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'custom_token'
export const DEFAULT_CURRENCY = 'usd'
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const SNAPCHAT_QUERY_PARAM = 'is_snapchat_payment'
export const LANG_QUERY_PARAM = 'lang'
export const KEYWORD_PARAM = 'lp_dynamic'
export const COHORT_QUERY_PARAM = 'cohort'
export const EMAIL_QUERY_PARAM = 'email'
export const INITIAL_LANGUAGE = 'en'
export const NO_GROWTH_BOOK_TEST = 'no_test'
export const EXPERIMENT_ID_NOT_SET = 'experiment_id_not_set'
export const MAX_VISIBLE_PROBLEM_AREAS_NUMBER = 2

export const USER_GOALS_GRAPH = {
  [UserGoal.WRINKLE_FREE_SKIN]: 'commonComponents.wrinkleFreeSkin',
  [UserGoal.REDUCE_FACE_FAT]: 'commonComponents.slimFace',
  [UserGoal.BOTH_OPTIONS]: 'commonComponents.idealSkin',
}

export const USER_GOALS_TITLE = {
  [UserGoal.WRINKLE_FREE_SKIN]: 'commonComponents.wrinkleFreeSkin',
  [UserGoal.REDUCE_FACE_FAT]: 'commonComponents.slimFace',
  [UserGoal.BOTH_OPTIONS]: 'commonComponents.perfectSkin',
}

export const DAYS_NUMBER = {
  [PageId.SHORT_PROGRAM_DESCRIPTION]: 30,
  [PageId.SHORT_PROGRAM_DESCRIPTION_SECOND]: 23,
  [PageId.PURCHASE_EMAIL]: 16,
  [PageId.PURCHASE]: 16,
}

export const enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELLED = 'cancelled',
  NOT_ACTIVE = 'not_active',
}

export const enum BillingCycle {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  TWELVE_MONTHS = 12,
}

export const BILLING_CYCLE_TEXTS = {
  [BillingCycle.ONE_MONTH]: 'unsubscribe.subscriptionDetails.oneMonth',
  [BillingCycle.THREE_MONTHS]: 'unsubscribe.subscriptionDetails.threeMonths',
  [BillingCycle.SIX_MONTHS]: 'unsubscribe.subscriptionDetails.sixMonths',
  [BillingCycle.NINE_MONTHS]: 'unsubscribe.subscriptionDetails.nineMonths',
  [BillingCycle.TWELVE_MONTHS]: 'unsubscribe.subscriptionDetails.twelveMonths',
}

export const enum TrialBillingCycle {
  SEVEN_DAYS = 7,
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const AGE_OPTIONS = [
  { value: '20', label: '18-29' },
  { value: '30', label: '30-39' },
  { value: '40', label: '40-49' },
  { value: '50', label: '50-59' },
  { value: '60', label: '60-69' },
  { value: '70', label: '70+' },
]

export const enum SubscriptionProduct {
  SEVEN_DAY = 7,
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const TRIAL_BILLING_CYCLE_TEXTS = {
  [TrialBillingCycle.SEVEN_DAYS]: 'unsubscribe.subscriptionDetails.sevenDays',
  [TrialBillingCycle.ONE_MONTH]: 'unsubscribe.subscriptionDetails.oneMonth',
  [TrialBillingCycle.THREE_MONTHS]:
    'unsubscribe.subscriptionDetails.threeMonths',
  [TrialBillingCycle.SIX_MONTHS]: 'unsubscribe.subscriptionDetails.sixMonths',
}

export const enum ScreenName {
  LONG_START_AGE = 'long_start_age',
  PURCHASE = 'onboarding',
  EMAIL_OFFER = 'email_offer',
  CANCEL_OFFER = 'cancel_offer',
  CANCEL_OFFER_NY = 'cancel_offer_ny',
  CANCEL_OFFER_LONG = 'paywall_cancel_offer',
  UPSELL_1_PLAN = 'upsell_1_plan',
  UPSELL_3_PLANS = 'upsell_3_plans',
  UPSELL_CANCEL_OFFER = 'upsell_cancel_offer',
  UPSELL_DISCOUNT_PLAN = 'upsell_discount_plan',
  UPSELL_SWITCHER = 'upsell_switcher',
  UPSELL_GUASHA = 'upsell_guasha',
  UPSELL_GUASHA_CANCEL = 'upsell_guasha_cancel',
  LONG_PAYWALL = 'long_paywall',
  LIVECHAT_UPGRADE = 'livechat_upgrade',
  CHECKOUT = 'checkout',
  LIVECHAT_WELLNESS_BUNDLE = 'livechat_wellness_bundle',
  LIVECHAT_WELLNESS_BUNDLE_CANCEL_OFFER = 'livechat_wellness_bundle_cancel_offer',
}

export const enum UpsellProduct {
  MIX = 'doubleChinAndFaceLifting',
  CHIN_SHAPER = 'doubleChin',
  FACE_LIFTING = 'faceLifting',
  WELLNESS_APPS_BUNDLE = 'bundle',
  AI_MIX = 'aiSkincareAssistantAndProductScanner',
  PRODUCT_SCANNER = 'productScanner',
  SKINCARE_ASSISTANT = 'aiSkincareAssistant',
}

export const UPSELL_PRODUCT_EVENT_NAMES_MAP = {
  [UpsellProduct.CHIN_SHAPER]: 'double_chin',
  [UpsellProduct.FACE_LIFTING]: 'face_lifting',
  [UpsellProduct.MIX]: 'double_chin_face_lifting',
  [UpsellProduct.WELLNESS_APPS_BUNDLE]: 'wellness_bundle',
  [UpsellProduct.PRODUCT_SCANNER]: 'product_scanner',
  [UpsellProduct.SKINCARE_ASSISTANT]: 'ai_assistant',
  [UpsellProduct.AI_MIX]: 'ai_assistant_product_scanner',
}

export const enum InAppProduct {
  MIX = 'guashaAndRoller',
  GUA_SHA = 'guasha',
  ROLLER = 'roller',
}

export const IN_APP_PRODUCT_EVENT_NAMES_MAP = {
  [InAppProduct.MIX]: 'natural_agate_heart_gua_sha_and_roller',
  [InAppProduct.GUA_SHA]: 'natural_agate_heart_gua_sha',
  [InAppProduct.ROLLER]: 'natural_agate_roller',
}

export const enum UpsellProductType {
  ONE_TIME = 'one_time',
}

export const enum FaceScanSkipScreen {
  INFO_SCREEN = 'info_screen',
  MAIN_SCREEN = 'screen',
}

export const POST_PAYMENT_PAGES: string[] = [
  PageId.UPSELL_PAYWALL,
  PageId.UPSELL_CANCEL_PAYWALL,
  PageId.IN_APP_PAYWALL,
  PageId.IN_APP_CANCEL_PAYWALL,
  PageId.FINISHING_TOUCHES,
  PageId.LOGIN,
  PageId.DOWNLOAD,
]

export const PAGES_WITHOUT_HEADER: string[] = [
  PageId.INTRO,
  PageId.FACE_SCAN_PREVIEW,
  PageId.FACE_SCAN,
  PageId.FACE_SCAN_RESULTS,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
  PageId.HOLIDAYS_PURCHASE_CANCEL_OFFER,
  PageId.PURCHASE_UPGRADE,
  PageId.SUBSCRIPTION_LIST_DETAILS,
  PageId.UNSUBSCRIBE,
  PageId.AGE_AS_INTRO,
  PageId.LONG_START_AGE,
  PageId.GENDER_AS_INTRO,
  PageId.START_GOAL_VIDEO_1,
  PageId.START_GOAL_VIDEO_2,
  PageId.START_GOAL_VIDEO_3,
  PageId.START_GOAL_VIDEO_4,
  PageId.LIKE_CHAT_INTRO,
  PageId.AGE_UNISEX_AS_INTRO,
]

export const PROBLEMATIC_AREAS_MAP = [
  { value: 'forehead', label: 'Forehead' },
  { value: 'eyes', label: 'Temples' },
  { value: 'eyes', label: 'Eyes' },
  { value: 'cheeks', label: 'Cheeks' },
  { value: 'chin', label: 'Jawline' },
  { value: 'mouth', label: 'Mouth' },
  { value: 'chin', label: 'Chin' },
  { value: 'neck', label: 'Neck' },
  { value: 'neck', label: 'Décolleté (neckline)' },
]

export const TARGET_AREA_OPTIONS = [
  {
    value: 'forehead',
    label: 'onboarding.problematicAreas.forehead',
    key: 'Forehead',
  },
  {
    value: 'temples',
    label: 'onboarding.problematicAreas.temples',
    key: 'Temples',
  },
  { value: 'eyes', label: 'onboarding.problematicAreas.eyes', key: 'Eyes' },
  {
    value: 'cheeks',
    label: 'onboarding.problematicAreas.cheeks',
    key: 'Cheeks',
  },
  {
    value: 'jawline',
    label: 'onboarding.problematicAreas.jawline',
    key: 'Jawline',
  },
  { value: 'mouth', label: 'onboarding.problematicAreas.mouth', key: 'Mouth' },
  { value: 'chin', label: 'onboarding.problematicAreas.chin', key: 'Chin' },
  { value: 'neck', label: 'onboarding.problematicAreas.neck', key: 'Neck' },
  {
    value: 'neckline',
    label: 'onboarding.problematicAreas.neckline',
    key: 'Décolleté (neckline)',
  },
]

export const SCALE_OPTION_VALUES = {
  ONE: 'one',
  TWO: 'two',
  THREE: 'three',
  FOUR: 'four',
  FIVE: 'five',
}

export const SUPPORTED_LOCALES_MAP: Record<
  Cohort,
  ('en' | 'fr' | 'es' | 'it')[]
> = {
  [Cohort.QUIZ_2]: ['en', 'fr', 'es'],
  [Cohort.LUVLY_EMAIL_7]: ['en', 'fr', 'es'],
  [Cohort.LUVLY_INTRO_FAST]: ['en', 'fr', 'es', 'it'],
  [Cohort.LUVLY_CHAT_UPGRADE_6_PDF]: ['en'],
  [Cohort.LUVLY_CHAT_UPGRADE_6_EX_PDF]: ['en'],
  [Cohort.LUVLY_CHAT_UPGRADE_9_PDF]: ['en'],
  [Cohort.LUVLY_CHAT_UPGRADE_12_PDF]: ['en'],
  [Cohort.LUVLY_CHAT_UPGRADE_12_EX_PDF]: ['en'],
  [Cohort.LUVLY_CHAT_UPGRADE_24_PDF]: ['en'],
  [Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF]: ['en'],
  [Cohort.LUVLY_CHAT_GUASHA]: ['en'],
  [Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE]: ['en'],
  [Cohort.LUVLY_EMAIL_8]: ['en', 'fr', 'es', 'it'],
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: ['en', 'fr', 'es'],
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: ['en', 'fr', 'es'],
  [Cohort.LUVLY_MARISSA]: ['en'],
  [Cohort.LUVLY_COSMETIC_SCANNER]: ['en'],
  [Cohort.LUVLY_9]: ['en', 'fr', 'es'],
  [Cohort.LUVLY_10]: ['en'],
  [Cohort.LUVLY_12]: ['en'],
  [Cohort.LUVLY_INTRO2T]: ['en', 'fr', 'es'],
  [Cohort.LUVLY_14]: ['en', 'fr', 'es'],
  [Cohort.LUVLY_15]: ['en'],
  [Cohort.LUVLY_17]: ['en'],
  [Cohort.LUVLY_18]: ['en'],
  [Cohort.LUVLY_CHAT]: ['en'],
  [Cohort.LUVLY_19]: ['en'],
  [Cohort.LUVLY_INTRO_SALE]: ['en', 'fr', 'es', 'it'],
  [Cohort.LUVLY_INTRO3_SALE]: ['en', 'fr', 'es', 'it'],
  [Cohort.LUVLY_12_SALE]: ['en'],
  [Cohort.LUVLY_20]: ['en'],
}

export const enum Language {
  ES = 'es',
  EN = 'en',
  FR = 'fr',
  IN_CONTEXT = 'af',
  IT = 'it',
}

export const SUPPORTED_LOCALES = ['en', 'es', 'fr', 'it']

export const EMAIL_DOMAINS = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'live.com',
  'msn.com',
  'me.com',
  'mac.com',
  'googlemail.com',
  'facebook.com',
  'verizon.net',
  'rediffmail.com',
  'zohomail.com',
  'zoho.com',
  'mail.com',
  'google.com',
  'comcast.com',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'att.net',
  'gmz.com',
]

export const enum CameraAccess {
  ALLOW = 'allow',
  DENY = 'deny',
}

export const ML_NO_PROBLEM_AREAS = 'not_found'

export const ML_DEFAULT_PROBLEM_AREAS = ['wrinkles']

export const LIVECHAT_ENABLED_COHORTS: Cohort[] = [
  Cohort.QUIZ_2,
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_COSMETIC_SCANNER,
  Cohort.LUVLY_9,
  Cohort.LUVLY_10,
  Cohort.LUVLY_12,
  Cohort.LUVLY_INTRO2T,
  Cohort.LUVLY_15,
  Cohort.LUVLY_17,
  Cohort.LUVLY_18,
  Cohort.LUVLY_19,
  Cohort.LUVLY_20,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_INTRO3_SALE,
]

export const LIVECHAT_UPGRADE_COHORTS: Cohort[] = [
  Cohort.LUVLY_CHAT_UPGRADE_6_PDF,
  Cohort.LUVLY_CHAT_UPGRADE_6_EX_PDF,
  Cohort.LUVLY_CHAT_UPGRADE_9_PDF,
  Cohort.LUVLY_CHAT_UPGRADE_12_PDF,
  Cohort.LUVLY_CHAT_UPGRADE_12_EX_PDF,
  Cohort.LUVLY_CHAT_UPGRADE_24_PDF,
  Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF,
]

export const COHORT_TO_TEST_MAP: Record<string, string> = {
  [Cohort.LUVLY_INTRO_FAST]: 'lv_178_occasion_screens_test',
}

export const TRIAL_SUBSCRIPTION_AVAILABLE_COHORTS: Cohort[] = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_COSMETIC_SCANNER,
  Cohort.LUVLY_9,
  Cohort.LUVLY_10,
  Cohort.LUVLY_12,
  Cohort.LUVLY_INTRO2T,
  Cohort.LUVLY_14,
  Cohort.LUVLY_15,
  Cohort.LUVLY_17,
  Cohort.LUVLY_18,
  Cohort.LUVLY_19,
  Cohort.LUVLY_20,
  Cohort.LUVLY_INTRO_SALE,
]

export const enum PlanAddition {
  BONUS = 'Bonus',
}

export const SEVEN_DAY_TRIAL_DURATION = '7'

export const MINIMUM_COUNT_OF_MONTH = 1

export const FREE_BONUS_FOR_UPGRADE = '0.00'

export const VAT_INCLUDED_COUNTRY = ['US', 'CA', 'AU']

export const LUVLY_INTRO_FAST_CONTEXT = 'intro_fast'
const COSMETIC_SCANNER_FLOW_CONTEXT = 'cosmetic'
const SENIOR_FLOW_CONTEXT = 'senior'
const INTRO2T_CONTEXT = 'intro2t'
const CHAT_CONTEXT = 'chat'
const UNISEX_CONTEXT = 'unisex'

export const I18N_CONTEXT_COHORT_MAP: Partial<Record<Cohort, string>> = {
  [Cohort.LUVLY_INTRO_FAST]: LUVLY_INTRO_FAST_CONTEXT,
  [Cohort.LUVLY_COSMETIC_SCANNER]: COSMETIC_SCANNER_FLOW_CONTEXT,
  [Cohort.LUVLY_12]: SENIOR_FLOW_CONTEXT,
  [Cohort.LUVLY_INTRO2T]: INTRO2T_CONTEXT,
  [Cohort.LUVLY_CHAT]: CHAT_CONTEXT,
  [Cohort.LUVLY_12_SALE]: SENIOR_FLOW_CONTEXT,
  [Cohort.LUVLY_20]: UNISEX_CONTEXT,
}

export const PROGRESS_STEPS_LENGTH_MAP: Partial<Record<Cohort, number>> = {
  [Cohort.LUVLY_12]: 33,
  [Cohort.LUVLY_CHAT]: 30,
  [Cohort.LUVLY_12_SALE]: 33,
}

export const GUASHA_META_DATA = {
  SKU: '04302',
  BARCODE: '783495438434',
  NAME: 'Agate Heart',
}

export const ROLLER_META_DATA = {
  SKU: '04301',
  BARCODE: '726872007779',
  NAME: 'Agate Roller',
}

export const GUASHA_ROLLER_META_DATA = {
  SKU: '04302,04301',
  BARCODE: '783495438434,726872007779',
  NAME: 'Agate Heart,Agate Roller',
}

export const IN_APP_META_DATA_MAP = {
  [InAppProduct.GUA_SHA]: GUASHA_META_DATA,
  [InAppProduct.ROLLER]: ROLLER_META_DATA,
  [InAppProduct.MIX]: GUASHA_ROLLER_META_DATA,
}

export const EU_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
]

export const ES_COUNTRY_CODES = ['CO', 'MX', 'AR', 'BR', 'PE', 'ES', 'PT']

export const enum CookieConsentOption {
  STRICTLY_NECESSARY = 'necessary',
  ANALYTICS_AND_PERFORMANCE = 'analytics',
  ADVERTIZING_AND_TARGETING = 'advertising',
  FUNCTIONAL_COOKIES = 'functional_cookies',
  REJECT_ALL = 'reject_all',
}

export const enum GAConsent {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export const enum FBConsent {
  REVOKE = 'revoke',
  GRANT = 'grant',
}

export const enum WellnessBundleApp {
  NUTRIMATE = 'NutriMate',
  DANCEBIT = 'Dancebit',
  FITME = 'FitMe',
}

export const enum HotJarClientId {
  PROD = '4966693',
  DEV = '3925319',
}

export const HOTJAR_AVAILABLE_COHORTS: Cohort[] = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_9,
  Cohort.LUVLY_10,
  Cohort.LUVLY_12,
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_19,
  Cohort.LUVLY_20,
  Cohort.LUVLY_INTRO3_SALE,
]

export const enum DynamicDiscountType {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}

export const enum DynamicDiscountTheme {
  VIOLET = 'violet',
  CORAL = 'coral',
  YELLOW = 'yellow',
}

export const NAVIGATION_FREE_COHORTS = [Cohort.LUVLY_12, Cohort.LUVLY_12_SALE]
